import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Spring } from 'react-burgers';
import './AccountMenu.scss';
import useResizeAware from 'react-resize-aware';

const accountMenuItems = [
  {
    id: '1',
    title: 'Burger'
  },
  {
    id: '2',
    path: '/my-account/my-profile',
    title: 'My profile'
  },
  {
    id: '3',
    path: '/my-account/favourites',
    title: 'Favourites'
  },
  {
    id: '4',
    path: '/my-account/my-ads',
    title: 'My ads'
  },
  {
    id: '5',
    path: '/my-account/recently-viewed',
    title: 'Recently viewed'
  },
  {
    id: '6',
    path: '/my-account/pricing',
    title: 'Pricing'
  },
  {
    id: '7',
    path: '/my-account/settings',
    title: 'Settings'
  }
];

const AccountMenu = () => {
  const [resizeListener, sizes] = useResizeAware();
  const [activeMenu, setActiveMenu] = useState(false);
  let listItem;

  if (sizes.width < 1121 && activeMenu) {
    listItem = 'account-menu-list__item show-list-item';
  } else {
    listItem = 'account-menu-list__item';
  }

  if (sizes.width > 1120) {
    listItem = 'account-menu-list__item';
  }

  const burgerListContent = accountMenuItems
    .filter(item => item.title === 'Burger')
    .map(item => {
      return (
        <React.Fragment key={item.id}>
          <Spring
            className="burger"
            active={activeMenu}
            onClick={() => setActiveMenu(!activeMenu)}
          />
        </React.Fragment>
      );
    });

  const listContent = accountMenuItems
    .filter(item => item.title !== 'Burger')
    .map(item => {
      return (
        <div className={'account-menu-list__item-blocks'} key={item.id}>
          <NavLink
            to={item.path}
            className="account-menu-list__link"
            activeClassName="active-account-menu-list__link"
            onClick={() =>  setActiveMenu(false)}
          >
            {item.title}
          </NavLink>
          <i className="icon-arrow-menu" />
        </div>
      );
    });

  return (
    <ul className="account-menu-list">
      {resizeListener}
      <li className="account-menu-list__burger-item">{burgerListContent}</li>
      <li className={listItem}>{listContent}</li>
    </ul>
  );
};

export default AccountMenu;
