import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PricingContent.scss';

const pricingLogo = require('../../images/section-logo.png');

const PricingContent = () => {
  const [pricing, setPricing] = useState([]);
  const [pricingIds, setPricingIds] = useState([]);

  const clickHandler = (id, arr) => {
    if (arr.includes(id)) {
      setPricingIds([...pricingIds.filter(item => item !== id)]);
    } else {
      setPricingIds(state => {
        return [...state, id];
      });
    }
  };

  useEffect(() => {
    axios
      .get(`/json/getPricing.json`)
      .then(res => setPricing(res.data.pricing));
  }, [setPricing]);

  return (
    <div className="pricing-content">
      {pricing.map(item => {
        return (
          <div
            className={
              pricingIds.includes(item.id)
                ? 'pricing-content-items active-item'
                : 'pricing-content-items'
            }
            key={item.id}
          >
            <div className="pricing-content-header">
              <img
                src={pricingLogo}
                alt="pricing-header-logo"
                className="pricing-content-header__img"
              />
              <div className="pricing-content-header-text-block">
                <p className="pricing-content-header-text-block__title">
                  {item.title}
                </p>
                <p className="pricing-content-header-text-block__subtitle">
                  {item.subtitle}
                </p>
              </div>
            </div>
            <div className="pricing-content-footer">
              <ul className="pricing-content-footer-list">
                {item.features.map(text => {
                  return (
                    <li
                      className="pricing-content-footer-list__item"
                      key={text + 1}
                    >
                      {text}
                    </li>
                  );
                })}
              </ul>
              <button
                className={
                  pricingIds.includes(item.id)
                    ? 'pricing-content-footer__btn active-btn'
                    : 'pricing-content-footer__btn'
                }
                onClick={() => clickHandler(item.id, pricingIds)}
              >
                {pricingIds.includes(item.id) ? (
                  <React.Fragment>
                    <i className="icon-arrow-checkbox active-icon" />
                    <span className="pricing-content-footer__btn-text">Active</span>
                  </React.Fragment>
                ) : (
                  'Get package'
                )}
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PricingContent;
