import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import 'semantic-ui-css/components/checkbox.css';
import './CustomCheckbox.scss';
import PropTypes from 'prop-types';

const CustomCheckbox = ({ checked, handleChange }) => {
  return (
    <React.Fragment>
      <Checkbox checked={checked} onChange={() => handleChange()} />
    </React.Fragment>
  );
};

CustomCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default CustomCheckbox;
