import React from 'react';
import NavigationSection from './sections/Header/NavigationSection';
import './App.scss';
import FooterSection from './sections/Footer/FooterSection';
import Main from './components/Main/Main';
import useResizeAware from 'react-resize-aware';
import HeaderSection from './sections/Header/HeaderSection';

function App() {
  const [resizeListener, sizes] = useResizeAware(); //use for check width and height of window

  return (
    <React.Fragment>
      <header className="header">
        <div className="lg-container">
          {resizeListener}
          <HeaderSection />
          <NavigationSection sizes={sizes} />
        </div>
      </header>
      <Main />
      <FooterSection />
    </React.Fragment>
  );
}

export default App;
