import React from 'react';
import './LegalSection.scss';

const listItems = [
  {
    id: '1',
    title: 'Subtitle here',
    text: `
    Yse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi 
    ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt 
    velit tempor irure mollit voluptate consequat commodo.Product description, dolor esse eu minim 
    incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit 
    cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure 
    mollit voluptate consequat commodo.Product description, dolor esse eu minim incididunt aliquip 
    dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit cupidatat eiusmod 
    excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure mollit voluptate consequat commodo.
    `
  },
  {
    id: '2',
    title: 'Subtitle here',
    text: `
    Yse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi 
    ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt 
    velit tempor irure mollit voluptate consequat commodo.Product description, dolor esse eu minim 
    incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit 
    cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure 
    mollit voluptate consequat commodo.Product description, dolor esse eu minim incididunt aliquip 
    dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit cupidatat eiusmod 
    excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure mollit voluptate consequat commodo.
    `
  },
  {
    id: '3',
    title: 'Subtitle here',
    text: `
    Yse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi 
    ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt 
    velit tempor irure mollit voluptate consequat commodo.Product description, dolor esse eu minim 
    incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit 
    cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure 
    mollit voluptate consequat commodo.Product description, dolor esse eu minim incididunt aliquip 
    dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit cupidatat eiusmod 
    excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure mollit voluptate consequat commodo.
    `
  },
  {
    id: '4',
    title: 'Subtitle here',
    text: `
    Yse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi 
    ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt 
    velit tempor irure mollit voluptate consequat commodo.Product description, dolor esse eu minim 
    incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit 
    cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure 
    mollit voluptate consequat commodo.Product description, dolor esse eu minim incididunt aliquip 
    dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit cupidatat eiusmod 
    excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure mollit voluptate consequat commodo.
    `
  },
  {
    id: '5',
    title: 'Subtitle here',
    text: `
    Yse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi 
    ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt 
    velit tempor irure mollit voluptate consequat commodo.Product description, dolor esse eu minim 
    incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit 
    cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure 
    mollit voluptate consequat commodo.Product description, dolor esse eu minim incididunt aliquip 
    dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit cupidatat eiusmod 
    excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure mollit voluptate consequat commodo.
    `
  },
  {
    id: '6',
    title: 'Subtitle here',
    text: `
    Yse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi 
    ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt 
    velit tempor irure mollit voluptate consequat commodo.Product description, dolor esse eu minim 
    incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit 
    cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure 
    mollit voluptate consequat commodo.Product description, dolor esse eu minim incididunt aliquip 
    dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit cupidatat eiusmod 
    excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure mollit voluptate consequat commodo.
    `
  },
  {
    id: '7',
    title: 'Subtitle here',
    text: `
    Yse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi 
    ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt 
    velit tempor irure mollit voluptate consequat commodo.Product description, dolor esse eu minim 
    incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit 
    cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure 
    mollit voluptate consequat commodo.Product description, dolor esse eu minim incididunt aliquip 
    dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit cupidatat eiusmod 
    excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure mollit voluptate consequat commodo.
    `
  },
  {
    id: '8',
    title: 'Subtitle here',
    text: `
    Yse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi 
    ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt 
    velit tempor irure mollit voluptate consequat commodo.Product description, dolor esse eu minim 
    incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit 
    cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure 
    mollit voluptate consequat commodo.Product description, dolor esse eu minim incididunt aliquip 
    dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit cupidatat eiusmod 
    excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure mollit voluptate consequat commodo.
    `
  }
];

const LegalSection = () => {
  return (
    <div className="legal">
      <ul className="legal-list">
        {listItems.map(item => {
          return (
            <li className="legal-list__item" key={item.id}>
              <h3 className="legal-list__title">{item.title}</h3>
              <p className="legal-list__text">{item.text}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LegalSection;
