import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './BottomBlock.scss';

const BottomBlock = ({ userInfo }) => {
  const validateInfo = obj => {
    const validateArr = Object.values(obj.validate);

    return (
      obj.policyChecked !== false &&
      obj.captchaChecked !== false &&
      !validateArr.includes(false) &&
      !validateArr.includes(null)
    );
  };

  return (
    <div className="bottom-block-sign-up">
      <button
        className={
          validateInfo(userInfo)
            ? 'bottom-block-sign-up__btn'
            : 'bottom-block-sign-up__btn disabled'
        }
      >
        Register
      </button>
      <p className="bottom-block-sign-up__text">
        Already a member? Please{' '}
        <Link to="/login" className="bottom-block-sign-up__link">
          login
        </Link>
      </p>
    </div>
  );
};

BottomBlock.propTypes = {
  userInfo: PropTypes.object
};

export default BottomBlock;
