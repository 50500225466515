import React from 'react';
import CustomCheckbox from '../../components/Common/CustomCheckbox';
import './CheckBlock.scss';
import PropTypes from 'prop-types';

const labelInfo = `
      By Registering, you agree that you’ve read and accepted our User Agreement, 
      you’re at least 18 years old, and you consent to our Privacy Notice and 
      receiving marketing communications from us.
`;

const CheckboxBlock = ({ checked, setChecked }) => {
  const changeHandler = () => {
    setChecked(state => {
      return {
        ...state,
        policyChecked: !state.policyChecked
      };
    });
  };

  return (
    <div className="checkbox-block">
      <CustomCheckbox checked={checked} handleChange={changeHandler} />
      <label className="checkbox-text">{labelInfo}</label>
    </div>
  );
};

CheckboxBlock.propTypes = {
  checked: PropTypes.bool,
  setChecked: PropTypes.func
};

export default CheckboxBlock;
