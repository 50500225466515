import React from 'react';
import SideBlocksItem from './SideBlocksItem';
import './SideBlocks.scss';

const items = [
  {
    id: '1',
    slug: ['login', 'register']
  },
  {
    id: '3',
    slug: 'post'
  }
];

const SideBlocks = () => {
  return (
    <div className="side-container">
      <ul className="side-container-list">
        {items.map(item => {
          return <SideBlocksItem key={item.id} slug={item.slug} />;
        })}
      </ul>
    </div>
  );
};

export default SideBlocks;
