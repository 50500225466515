import React from 'react';
import './NotifyBottom.scss';
import CustomCheckboxToggle from '../Common/CustomCheckboxToggle';

const labelItems = [
  {
    id: '5',
    label: 'New requests'
  },
  {
    id: '6',
    label: 'New messages'
  }
];

const NotifyBottom = () => {
  return (
    <div className="notify-bottom">
      <p className="notify-bottom__title">Notify me about</p>
      <div className="notify-bottom-checkbox-block">
        {labelItems.map(item => {
          return (
            <CustomCheckboxToggle
              label={item.label}
              key={item.id}
              id={item.id}
              className="notify-bottom-checkbox-block__item"
            />
          );
        })}
      </div>
    </div>
  );
};

export default NotifyBottom;
