import {
  SET_PERSONAL_ACCOUNT_DATA,
  SET_VALID_FIRST_NAME,
  SET_INVALID_FIRST_NAME,
  SET_VALID_LAST_NAME,
  SET_INVALID_LAST_NAME,
  SET_VALID_EMAIL,
  SET_INVALID_EMAIL,
  SET_PERSONAL_AVATAR
} from './personalAccountActions';

const initialState = {
  personalAccountData: {},
  validation: {
    email: true,
    firstName: true,
    lastName: true
  }
};

export const personalAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PERSONAL_ACCOUNT_DATA:
      return {
        ...state,
        personalAccountData: { ...action.payload }
      };

    case SET_VALID_FIRST_NAME:
      return {
        ...state,
        personalAccountData: {
          ...state.personalAccountData,
          firstName: action.payload
        },
        validation: {
          ...state.validation,
          firstName: true
        }
      };

    case SET_INVALID_FIRST_NAME:
      return {
        ...state,
        personalAccountData: {
          ...state.personalAccountData,
          firstName: action.payload
        },
        validation: {
          ...state.validation,
          firstName: false
        }
      };

    case SET_VALID_LAST_NAME:
      return {
        ...state,
        personalAccountData: {
          ...state.personalAccountData,
          lastName: action.payload
        },
        validation: {
          ...state.validation,
          lastName: true
        }
      };

    case SET_INVALID_LAST_NAME:
      return {
        ...state,
        personalAccountData: {
          ...state.personalAccountData,
          lastName: action.payload
        },
        validation: {
          ...state.validation,
          lastName: false
        }
      };

    case SET_VALID_EMAIL:
      return {
        ...state,
        personalAccountData: {
          ...state.personalAccountData,
          email: action.payload
        },
        validation: {
          ...state.validation,
          email: true
        }
      };

    case SET_INVALID_EMAIL:
      return {
        ...state,
        personalAccountData: {
          ...state.personalAccountData,
          email: action.payload
        },
        validation: {
          ...state.validation,
          email: false
        }
      };

    case SET_PERSONAL_AVATAR:
      return {
        ...state,
        personalAccountData: {
          ...state.personalAccountData,
          img: action.payload.src,
          imgFile: action.payload.file
        }
      };

    default:
      return state;
  }
};
