import React from 'react';
import './ContactInputBlocks.scss';
import CustomInput from '../Common/CustomInput';
import CustomTextarea from '../Common/CustomTextarea';

const ContactInputBlocks = () => {
  return (
    <div className="contact-input">
      <div className="contact-input-wrapper">
        <CustomInput
          type="text"
          placeholder="First name"
          classForInput="contact-input__item"
        />
        <CustomInput
          type="text"
          placeholder="Last name"
          classForInput="contact-input__item"
        />
        <CustomInput
          type="text"
          placeholder="Phone number"
          classForInput="contact-input__item"
        />
        <CustomInput
          type="email"
          placeholder="Email"
          classForInput="contact-input__item"
        />
      </div>
      <CustomTextarea
        classForContainer="contact-text-area__item"
        placeholder="Your message"
      />
    </div>
  );
};

export default ContactInputBlocks;
