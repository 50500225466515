import React from 'react';
import PersonalAccountContainer from '../../components/MyProfile/PersonalAccountContainer';
import SelectProductsContainer from '../../components/MyProfile/SelectProductsContainer';
import Notify from '../../components/MyProfile/Notify';
import BusinessAccountContainer from '../../components/MyProfile/BusinessAccountContainer';
import MyAddresses from '../../components/MyProfile/MyAddresses';
import NotifyBottom from '../../components/MyProfile/NotifyBottom';
import './MyProfileSubpage.scss';

const MyProfileSubpage = () => {
  return (
    <div className="my-profile">
      <PersonalAccountContainer />
      <SelectProductsContainer />
      <Notify />
      <BusinessAccountContainer />
      <MyAddresses />
      <NotifyBottom />
    </div>
  );
};

export default MyProfileSubpage;
