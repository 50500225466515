import { connect } from 'react-redux';
import { setFilterData } from '../../store/filter/filterActions';
import { setCategoriesData } from '../../store/categories/categoriesActions';
import SelectProducts from './SelectProducts';

const mapStateToProps = state => {
  return {
    categoriesData: state.categoriesReducer.categoriesData
  };
};

const mapDispatchToProps = {
  setFilterData,
  setCategoriesData
};

const SelectProductsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectProducts);

export default SelectProductsContainer;
