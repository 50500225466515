import React from 'react';
import Breadcrumbs from '../../routes';
import BusinessSection from '../../sections/Business/BusinessSection';

const BusinessPage = () => {
  return (
    <React.Fragment>
      <Breadcrumbs />
      <BusinessSection />
    </React.Fragment>
  );
};

export default BusinessPage;
