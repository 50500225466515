import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import Breadcrumbs from '../../routes';
import MyAccountSection from '../../sections/MyAccountSection/MyAccountSection';

const MyAccountPage = () => {
  const location = useLocation();

  if (location.pathname === '/my-account') {
    return <Redirect to="/my-account/my-profile" />;
  }

  return (
    <React.Fragment>
      <Breadcrumbs />
      <MyAccountSection />
    </React.Fragment>
  );
};

export default MyAccountPage;
