import React from 'react';
import CustomTitle from '../../components/Common/CustomTitle';
import './RecentlyViewedSubpage.scss';
import RecentlyViewedContent from '../../components/RecentlyViewed/RecentlyViewedContent';

const RecentlyViewedSubpage = () => {
  return (
    <div className="recently-view">
      <CustomTitle title="Recently viewed" className="recently-view__title" />
      <RecentlyViewedContent />
    </div>
  );
};

export default RecentlyViewedSubpage;
