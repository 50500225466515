export const SET_BUSINESS_ACCOUNT_DATA = 'SET_BUSINESS_ACCOUNT_DATA';
export const SET_BUSINESS_TYPE_DATA = 'SET_BUSINESS_TYPE_DATA';
export const SET_SELECTED_BUSINESS_TYPE = 'SET_SELECTED_BUSINESS_TYPE';
export const SET_BUSINESS_NAME = 'SET_BUSINESS_NAME';
export const SET_BUSINESS_DESCRIPTION = 'SET_BUSINESS_DESCRIPTION';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_BUSINESS_AVATAR = 'SET_BUSINESS_AVATAR';

export const setBusinessAccountData = val => {
  return {
    type: SET_BUSINESS_ACCOUNT_DATA,
    payload: val
  };
};

export const setBusinessTypeData = arr => {
  return {
    type: SET_BUSINESS_TYPE_DATA,
    payload: arr
  };
};

export const setSelectedBusinessType = obj => {
  return {
    type: SET_SELECTED_BUSINESS_TYPE,
    payload: obj
  };
};

export const setBusinessName = str => {
  return {
    type: SET_BUSINESS_NAME,
    payload: str
  };
};

export const setBusinessDescription = str => {
  return {
    type: SET_BUSINESS_DESCRIPTION,
    payload: str
  };
};

export const setAddress = str => {
  return {
    type: SET_ADDRESS,
    payload: str
  };
};

export const setAvatar = (str, file) => {
  return {
    type: SET_BUSINESS_AVATAR,
    payload: {
      src: str,
      file: file
    }
  };
};
