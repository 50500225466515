import React, { useEffect, useState } from 'react';
import './SelectProducts.scss';
import axios from 'axios';
import SearchItems from '../Search/SearchItems';

const listItems = [
  {
    id: '1',
    placeholderTitle: 'Select a category'
  },
  {
    id: '2',
    placeholderTitle: 'Select sub-category'
  },
  {
    id: '3',
    btnTitle: 'Add',
    placeholderTitle: 'Or type product name here…'
  }
];

const SelectProducts = ({
  setCategoriesData,
  categoriesData,
  setFilterData
}) => {
  const [searchData, setSearchData] = useState({
    selectedCategory: null,
    selectedSubcategory: null,
    subcategories: null,
    searchText: '',
    products: [
      { title: 'Lasagna', id: '55' },
      { title: 'Noodles', id: '56' },
      { title: 'Meat', id: '57' },
      { title: 'Chicken', id: '58' }
    ],
    foundProducts: [],
    selectedProduct: null,
    isDownshiftOpen: false
  });

  const [category, setCategory] = useState(null);
  const [subcategory, setSubcategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const setSelectedItemsHandler = (
    category,
    subcategory,
    searchVal,
    selectedProduct
  ) => {
    setFilterData(category, subcategory, selectedProduct);

    const getItem = (id, val, target) => {
      return (
        <span className="selected-items-text-wrapper" key={id}>
          <span className="selected-items__text">{val}</span>
          <i
            className="icon-remove-category"
            onClick={
              target === 'category'
                ? () => setCategory(null)
                : target === 'subcategory'
                ? () => setSubcategory(null)
                : () => setSelectedProduct(null)
            }
          />
        </span>
      );
    };

    if (subcategory === null && selectedProduct === null) {
      setCategory(getItem(category.id, category.value, 'category'));
    }

    if (
      subcategory === null &&
      selectedProduct !== null &&
      selectedProduct.value !== searchVal
    ) {
      setCategory(getItem(category.id, category.value, 'category'));
    }

    if (
      subcategory === null &&
      selectedProduct !== null &&
      selectedProduct.value !== searchVal
    ) {
      setCategory(getItem(category.id, category.value, 'category'));
    }

    if (
      category !== null &&
      subcategory === null &&
      selectedProduct !== null &&
      selectedProduct.value === searchVal
    ) {
      setCategory(getItem(category.id, category.value, 'category'));
      setSelectedProduct(
        getItem(selectedProduct.id, selectedProduct.value, 'search')
      );
    }

    if (
      category === null &&
      subcategory === null &&
      selectedProduct !== null &&
      selectedProduct.value === searchVal
    ) {
      setSelectedProduct(
        getItem(selectedProduct.id, selectedProduct.value, 'search')
      );
    }

    /*if (subcategory !== null) {
      setSubcategory(getItem(subcategory.id, subcategory.value, 'subcategory'));
    }*/

    if (subcategory !== null && category !== null) {
      setCategory(getItem(category.id, category.value, 'category'));
      setSubcategory(getItem(subcategory.id, subcategory.value, 'subcategory'));
    }

    if (
      selectedProduct !== null &&
      selectedProduct.value === searchVal &&
      subcategory !== null &&
      category !== null
    ) {
      setSelectedProduct(
        getItem(selectedProduct.id, selectedProduct.value, 'search')
      );

      setCategory(getItem(category.id, category.value, 'category'));

      setSubcategory(getItem(subcategory.id, subcategory.value, 'subcategory'));
    }
  };

  useEffect(() => {
    axios.get(`/json/getCategories.json`).then(res => {
      setCategoriesData(res.data.categories);
    });
  }, [setCategoriesData]);

  return (
    <div className="select-products">
      <p className="select-products__title">
        Select products you’re interested in
      </p>
      <div className="select-products-block">
        {listItems.map(item => {
          return (
            <SearchItems
              key={item.id}
              title={item.title}
              btnTitle={item.btnTitle}
              placeholderTitle={item.placeholderTitle}
              categoryClass="select-category-products"
              subcategoryClass="select-subcategory-products"
              inputClass="input-products"
              formInputClass="select-products-block__input"
              btnClass="btn-products"
              btnTextClass="select-products-block__text"
              searchData={searchData}
              setSearchData={setSearchData}
              categoriesData={categoriesData}
              setSelectedItemsHandler={setSelectedItemsHandler}
            />
          );
        })}
      </div>
      <div className="selected-items">
        {category === null ? null : category}
        {subcategory === null ? null : subcategory}
        {selectedProduct === null ? null : selectedProduct}
      </div>
      <button
        className={
          category === null && selectedProduct === null
            ? 'select-products_btn disabled'
            : 'select-products_btn'
        }
        type="button"
      >
        Save changes
      </button>
    </div>
  );
};

export default SelectProducts;
