import React from 'react';
import './CustomCheckboxToggle.scss';
import PropTypes from 'prop-types';

const CustomCheckboxToggle = ({
  checked,
  setChecked,
  label,
  id,
  className
}) => {
  return (
    <div
      className={
        className ? 'toggle-container ' + className : 'toggle-container'
      }
    >
      <input type="checkbox" id={id} className="toggle-container__input" />
      <label className="toggle-container-circle" htmlFor={id} />
      <label className="toggle-container__text">{label}</label>
    </div>
  );
};

CustomCheckboxToggle.propTypes = {
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string
};

export default CustomCheckboxToggle;
