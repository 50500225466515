import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import AddressesInputBlock from './AddressesInputBlock';
import CustomTitle from '../Common/CustomTitle';
import './MyAddresses.scss';

const addressItems = [
  {
    id: '1',
    text: '9a Newcastle Street, Rose Bay 2029',
    functionalityText: 'Delete'
  },
  {
    id: '2',
    text: '10B Random Street',
    functionalityText: 'Delete'
  }
];

const MyAddresses = () => {
  const [address, setAddress] = useState(addressItems);

  const [addressFields, setAddressFields] = useState({
    streetAddress: '',
    selectedCity: null,
    selectedStateProvince: null,
    selectedCountry: null
  });

  const [open, setOpen] = useState(false);

  const deleteAddress = id => {
    setAddress([...address.filter(item => item.id !== id)]);
  };

  const checkAddressFields = obj => {
    const arr = Object.values(obj);

    return !arr.includes('') && !arr.includes(null);
  };

  const addAddress = () => {
    setAddress(state => {
      return [
        ...state,
        {
          id: Date.now().toString(),
          text: `${addressFields.streetAddress +
            ', ' +
            addressFields.selectedStateProvince.value +
            ', ' +
            addressFields.selectedCity.value +
            ', ' +
            addressFields.selectedCountry.value}`,
          functionalityText: 'Delete'
        }
      ];
    });
  };

  return (
    <div className="my-addresses">
      <p className="my-addresses__title">My addresses</p>
      <ul className="my-addresses-list">
        {address.map(item => {
          return (
            <li className="my-addresses-list__item" key={item.id}>
              <span className="my-addresses-list__text-info">{item.text}</span>
              <span
                className="my-addresses-list__text-functionality"
                onClick={() => deleteAddress(item.id)}
              >
                {item.functionalityText}
              </span>
            </li>
          );
        })}
      </ul>
      <button className="my-addresses__btn" onClick={() => setOpen(true)}>
        Add new address
      </button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        showCloseIcon={false}
        center
      >
        <CustomTitle
          title="Add new address"
          className="my-addresses-modal__title"
        />
        <i className="icon-close-modal" onClick={() => setOpen(false)} />
        <form className="my-addresses-modal-form">
          <AddressesInputBlock
            addressFields={addressFields}
            setAddressFields={setAddressFields}
          />
          <button
            type="button"
            className={
              checkAddressFields(addressFields)
                ? 'my-addresses-modal-form__btn'
                : 'my-addresses-modal-form__btn disabled'
            }
            onClick={() => addAddress()}
          >
            Send address
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default MyAddresses;
