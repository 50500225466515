import React, { useState } from 'react';
import './SetUpProfileSection.scss';
import CustomTitle from '../../components/Common/CustomTitle';
import InputBlock from '../../components/SetUpProfile/InputBlock';
import CheckboxBlock from '../../components/SetUpProfile/CheckboxBlock';
import BottomBlock from '../../components/SetUpProfile/BottomBlock';

const SetUpProfileSection = () => {
  const [profile, setUpProfile] = useState({
    firstName: '',
    secondName: '',
    email: '',
    address: '',
    validate: {
      email: null,
      firstName: null,
      secondName: null
    },
    checkedSupplier: {
      value: false,
      status: 'not-active'
    },
    checkedBuyer: {
      value: false,
      status: 'not-active'
    },
    checkedBoth: {
      value: false,
      status: 'not-active'
    }
  });

  const submitHandler = event => {
    event.preventDefault();
  };

  return (
    <div className="set-up-profile">
      <CustomTitle title="Set-up your profile" />
      <form onSubmit={submitHandler}>
        <InputBlock profile={profile} setUpProfile={setUpProfile} />
        <CheckboxBlock profile={profile} setUpProfile={setUpProfile} />
        <BottomBlock profile={profile} setUpProfile={setUpProfile} />
      </form>
    </div>
  );
};

export default SetUpProfileSection;
