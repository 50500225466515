import { connect } from 'react-redux';
import {
  setBusinessAccountData,
  setBusinessTypeData,
  setAddress,
  setSelectedBusinessType,
  setBusinessName,
  setBusinessDescription,
  setAvatar
} from '../../store/businessAccount/businessAccountActions';
import BusinessAccount from './BusinessAccount';

const mapStateToProps = state => {
  return {
    businessAccountData: state.businessAccountReducers.businessAccountData,
    businessTypeData: state.businessAccountReducers.businessTypeData
  };
};

const mapDispatchToProps = {
  setBusinessAccountData,
  setBusinessTypeData,
  setAddress,
  setBusinessName,
  setBusinessDescription,
  setSelectedBusinessType,
  setAvatar
};

const BusinessAccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessAccount);

export default BusinessAccountContainer;
