import React from 'react';
import './FavouritesSubpage.scss';
import CustomTitle from '../../components/Common/CustomTitle';
import FavouritesItemsContainer from '../../components/Favorites/FavouritesItemsContainer';

const FavouritesSubpage = () => {
  return (
    <div className="favourites">
      <CustomTitle title="Favourites" className="favourites__title" />
      <FavouritesItemsContainer />
    </div>
  );
};

export default FavouritesSubpage;
