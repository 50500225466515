import React from 'react';
import GoogleLogin from 'react-google-login';
import './GoogleBlock.scss';

const GoogleBlock = () => {
  const successRepGoogle = response => {
    console.log(response);
  };

  const failureGoogle = response => {
    console.log(response);
  };

  return (
    <div className="google-block">
      <GoogleLogin
        clientId="792582550820-ulj4s95ufa658pl8fslkck4cmnlgchf7.apps.googleusercontent.com"
        buttonText="Sign in with Google"
        onSuccess={successRepGoogle}
        onFailure={failureGoogle}
        cookiePolicy={'single_host_origin'}
        className="custom-google-button-class"
        icon={false}
      />
      <i className="icon-google" />
    </div>
  );
};

export default GoogleBlock;
