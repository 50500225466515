import React from 'react';
import CustomInput from '../../components/Common/CustomInput';
import './InputBlock.scss';
import PropTypes from 'prop-types';
import { nameRegexp, emailRegexp, passRegexp } from '../../regExp';

const InputBlock = ({ setUserInfo, userInfo }) => {
  const handleChangeFrsName = event => {
    event.persist();
    if (nameRegexp.test(event.target.value)) {
      setUserInfo(state => {
        return {
          ...state,
          firstName: event.target.value,
          validate: {
            ...state.validate,
            firstName: true
          }
        };
      });
    } else {
      setUserInfo(state => {
        return {
          ...state,
          firstName: event.target.value,
          validate: {
            ...state.validate,
            firstName: false
          }
        };
      });
    }
  };

  const handleChangeSecName = event => {
    event.persist();
    if (nameRegexp.test(event.target.value)) {
      setUserInfo(state => {
        return {
          ...state,
          lastName: event.target.value,
          validate: {
            ...state.validate,
            lastName: true
          }
        };
      });
    } else {
      setUserInfo(state => {
        return {
          ...state,
          lastName: event.target.value,
          validate: {
            ...state.validate,
            lastName: false
          }
        };
      });
    }
  };

  const handleChangeEmail = event => {
    event.persist();
    if (emailRegexp.test(event.target.value)) {
      setUserInfo(state => {
        return {
          ...state,
          email: event.target.value,
          validate: {
            ...state.validate,
            email: true
          }
        };
      });
    } else {
      setUserInfo(state => {
        return {
          ...state,
          email: event.target.value,
          validate: {
            ...state.validate,
            email: false
          }
        };
      });
    }
  };

  const handleChangePassword = event => {
    event.persist();
    if (passRegexp.test(event.target.value)) {
      setUserInfo(state => {
        return {
          ...state,
          password: event.target.value,
          validate: {
            ...state.validate,
            password: true
          }
        };
      });
    } else {
      setUserInfo(state => {
        return {
          ...state,
          password: event.target.value,
          validate: {
            ...state.validate,
            password: false
          }
        };
      });
    }
  };

  return (
    <div className="input-block-sign-up">
      <div className="input-section">
        <CustomInput
          value={userInfo.firstName}
          handleChange={handleChangeFrsName}
          type="text"
          placeholder="First name"
          classForInput={
            userInfo.validate.firstName
              ? 'input-block-sign-up__item valid'
              : 'input-block-sign-up__item invalid'
          }
          required
        />
        <CustomInput
          value={userInfo.lastName}
          handleChange={handleChangeSecName}
          type="text"
          placeholder="Last name"
          classForInput={
            userInfo.validate.lastName
              ? 'input-block-sign-up__item valid'
              : 'input-block-sign-up__item invalid'
          }
          required
        />
      </div>
      <div className="input-section">
        <CustomInput
          value={userInfo.email}
          handleChange={handleChangeEmail}
          type="email"
          placeholder="Email address"
          classForInput={
            userInfo.validate.email
              ? 'input-block-sign-up__item valid'
              : 'input-block-sign-up__item invalid'
          }
          required
        />
        <CustomInput
          value={userInfo.password}
          handleChange={handleChangePassword}
          type="password"
          placeholder="Create password"
          classForInput={
            userInfo.validate.password
              ? 'input-block-sign-up__item valid'
              : 'input-block-sign-up__item invalid'
          }
          required
        />
      </div>
    </div>
  );
};

InputBlock.propTypes = {
  userInfo: PropTypes.object,
  setUserInfo: PropTypes.func
};

export default InputBlock;
