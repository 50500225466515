import React from 'react';
import './BottomBlock.scss';
import CustomSelectLocation from '../Common/CustomSelectLocation';
import PropTypes from 'prop-types';

const BottomBlock = ({ profile, setUpProfile }) => {
  const validateInfo = obj => {
    const firstArr = Object.values(obj).filter(
      item => typeof item !== 'object'
    );
    const secondArr = [
      ...firstArr,
      ...Object.values(obj.validate),
      ...Object.values(obj.checkedSupplier).filter(item => item === true),
      ...Object.values(obj.checkedBuyer).filter(item => item === true),
      ...Object.values(obj.checkedBoth).filter(item => item === true)
    ];
    return (
      !secondArr.includes('') &&
      !secondArr.includes(false) &&
      secondArr.length === 8
    );
  };

  return (
    <div className="bottom-block-set-up-profile">
      <p className="bottom-block-set-up-profile__title">I am located in</p>
      <CustomSelectLocation
        className="bottom-select-wrapper"
        placeholder="Select"
        icon="icon-map"
        info={profile}
        setInfo={setUpProfile}
      />
      <div className="bottom-block-set-up-profile-section">
        <button
          className={
            validateInfo(profile)
              ? 'bottom-block-set-up-profile-section__sm-btn'
              : 'bottom-block-set-up-profile-section__sm-btn disabled'
          }
          onClick={() => console.log(profile)}
        >
          Start browsing
        </button>
        <button
          className={
            validateInfo(profile)
              ? 'bottom-block-set-up-profile-section__lg-btn'
              : 'bottom-block-set-up-profile-section__lg-btn disabled'
          }
        >
          Personalize your account
        </button>
      </div>
    </div>
  );
};

BottomBlock.propTypes = {
  profile: PropTypes.object,
  setUpProfile: PropTypes.func
};

export default BottomBlock;
