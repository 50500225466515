import React from 'react';
import './AccountProducts.scss';
import PropTypes from 'prop-types';

const AccountProducts = ({ products, extend }) => {
  const myAdsContent = (
    subtitle,
    info,
    slug,
    titleLink,
    hartIcon,
    description,
    edit,
    preview
  ) => {
    return (
      <React.Fragment>
        <p className="products-list__subtitle">{subtitle}</p>
        <div className="products-list__text-info">
          {info + ', '}
          {titleLink}
        </div>
        <i className={hartIcon + ' products-icon'} />
        <p className="products-list__text-description">{description}</p>
        <div className="products-list-btn-section">
          <button className="products-list-btn-section__edit">{edit}</button>
          <button className="products-list-btn-section__preview">
            {preview}
          </button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <ul className="products-list">
      {products.map(item => {
        return (
          <li className={'products-list__item'} key={item.id}>
            <div className="products-list-product-header">
              <img
                src={item.img}
                alt="products-img"
                className="products-list__img"
              />
              <div className="products-list__text-price">
                <div className="products-list-price-block">
                  <p className="products-list-price-block__text-dollar">$ </p>
                  {item.price}
                </div>
              </div>
            </div>
            <div className="products-product-footer">
              <p className="products-list__title">{item.title}</p>
              {extend === 'My Ads' ? (
                myAdsContent(
                  item.subtitle,
                  item.info,
                  item.slug,
                  item.titleLink,
                  item.iconFill,
                  item.productsDescription,
                  item.edit,
                  item.preview
                )
              ) : (
                <React.Fragment>
                  <p className="products-list__subtitle">{item.subtitle}</p>
                  <div className="products-list__text-info">
                    {item.info + ', '}
                    {item.titleLink}
                  </div>
                  <i className={item.iconFill + ' products-icon'} />
                </React.Fragment>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

AccountProducts.propTypes = {
  products: PropTypes.array.isRequired,
  extend: PropTypes.string
};

export default AccountProducts;
