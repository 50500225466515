import React from 'react';
import './BottomBlock.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const BottomBlock = ({ userInfo }) => {
  const validateInfo = obj => {
    const arr = Object.values(obj.validate);

    return !arr.includes(false) && !arr.includes(null);
  };

  return (
    <div className="bottom-block-sign-in">
      <button
        className={
          validateInfo(userInfo)
            ? 'bottom-block-sign-in__btn'
            : 'bottom-block-sign-in__btn disabled'
        }
        onClick={() => console.log(userInfo)}
      >
        Login
      </button>
      <p className="bottom-block-sign-in__text">
        Don’t have an account? Please{' '}
        <Link to="/register" className="bottom-block-sign-up__link">
          register
        </Link>
      </p>
    </div>
  );
};

BottomBlock.propTypes = {
  userInfo: PropTypes.object
};

export default BottomBlock;
