import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AccountProducts from '../Account/AccountProducts';

const RecentlyViewedContent = () => {
  const [viewed, setViewed] = useState([]);

  useEffect(() => {
    axios
      .get(`/json/getRecentlyViewed.json`)
      .then(res => setViewed(res.data.recentlyViewed));
  }, [setViewed]);

  return <AccountProducts products={viewed} />;
};

export default RecentlyViewedContent;
