import React from 'react';
import HomePage from './pages/HomePage/HomePage';
import AboutPage from './pages/AboutPage/AboutPage';
import FaqsSupportPage from './pages/Faqs&SupportPage/Faqs&SupportPage';
import LegalPage from './pages/LegalPage/LegalPage';
import BusinessPage from './pages/BusinessPage/BusinessPage';
import ContactPage from './pages/ContactPage/ContactPage';
import PostPage from './pages/PostPage/PostPage';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import SignInPage from './pages/SignInPage/SignInPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import CreatePasswordPage from './pages/CreatePasswordPage/CreatePasswordPage';
import SetUpProfilePage from './pages/SetUpProfilePage/SetUpProfilePage';
import MyAccountPage from './pages/MyAccountPage/MyAccountPage';
import ProductItemsPage from './pages/ProductItemsPage/ProductItemsPage';
import MyProfileSubpage from './subpages/MyAccountSubPage/MyProfileSubpage';
import FavouritesSubpage from './subpages/MyAccountSubPage/FavouritesSubpage';
import MyAdsSubpage from './subpages/MyAccountSubPage/MyAdsSubpage';
import RecentlyViewedSubpage from './subpages/MyAccountSubPage/RecentlyViewedSubpage';
import MessagesSubpage from './subpages/MyAccountSubPage/MessagesSubpage';
import PricingSubpage from './subpages/MyAccountSubPage/PricingSubpage';
import SettingsSubpage from './subpages/MyAccountSubPage/SettingsSubpage';
import { Breadcrumbs } from './components/Breadcrumbs/Breadcrumbs';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

export const routes = {
  defaultRoutes: [
    {
      id: '1',
      path: '/',
      exact: true,
      breadcrumb: 'Home',
      component: () => <HomePage />
    },
    {
      id: '2',
      path: '/about',
      breadcrumb: 'About',
      component: () => <AboutPage />
    },
    {
      id: '3',
      path: '/faqs&support',
      breadcrumb: 'FAQs & Support',
      component: () => <FaqsSupportPage />
    },
    {
      id: '4',
      path: '/legal',
      breadcrumb: 'Legal',
      component: () => <LegalPage />
    },
    {
      id: '5',
      path: '/business',
      breadcrumb: 'For business',
      component: () => <BusinessPage />
    },
    {
      id: '6',
      path: '/contact',
      breadcrumb: 'Contact us',
      component: () => <ContactPage />
    },
    {
      id: '7',
      path: '/post',
      breadcrumb: 'Post an ad',
      component: () => <PostPage />
    },
    {
      id: '8',
      path: '/register',
      breadcrumb: 'Register',
      component: () => <SignUpPage />
    },
    {
      id: '9',
      path: '/login',
      breadcrumb: 'Login',
      component: () => <SignInPage />
    },
    {
      id: '10',
      path: '/forgot-pass',
      breadcrumb: 'Forgot password',
      component: () => <ForgotPasswordPage />
    },
    {
      id: '11',
      path: '/create-pass',
      breadcrumb: 'Create a new password',
      component: () => <CreatePasswordPage />
    },
    {
      id: '12',
      path: '/set-up-profile',
      breadcrumb: 'Set-up your profile',
      component: () => <SetUpProfilePage />
    },
    {
      id: '13',
      path: '/my-account',
      breadcrumb: 'My account',
      component: () => <MyAccountPage />
    },
    {
      id: '14',
      path: '/product-items',
      breadcrumb: 'Products',
      component: () => <ProductItemsPage />
    }
  ],
  nestedRoutes: {
    myAccount: [
      {
        id: '1',
        path: '/my-account/my-profile',
        exact: true,
        breadcrumb: 'My profile',
        component: () => <MyProfileSubpage />
      },
      {
        id: '2',
        path: '/my-account/favourites',
        breadcrumb: 'Favourites',
        component: () => <FavouritesSubpage />
      },
      {
        id: '3',
        path: '/my-account/my-ads',
        breadcrumb: 'My ads',
        component: () => <MyAdsSubpage />
      },
      {
        id: '4',
        path: '/my-account/recently-viewed',
        breadcrumb: 'Recently viewed',
        component: () => <RecentlyViewedSubpage />
      },
      {
        id: '5',
        path: '/my-account/messages',
        breadcrumb: 'Messages',
        component: () => <MessagesSubpage />
      },
      {
        id: '6',
        path: '/my-account/pricing',
        breadcrumb: 'Pricing',
        component: () => <PricingSubpage />
      },
      {
        id: '7',
        path: '/my-account/settings',
        breadcrumb: 'Settings',
        component: () => <SettingsSubpage />
      }
    ]
  }
};

const filteredRoutes = routes.defaultRoutes.map(item => {
  return {
    path: item.path,
    breadcrumb: item.breadcrumb
  };
});

const filteredNestedRoutes = routes.nestedRoutes.myAccount.map(item => {
  return {
    path: item.path,
    breadcrumb: item.breadcrumb
  };
});

export default withBreadcrumbs([...filteredRoutes, ...filteredNestedRoutes])(
  Breadcrumbs
);
