import React, { useEffect, useState } from 'react';
import AccountProducts from '../Account/AccountProducts';
import axios from 'axios';

const FavouritesContent = ({ productsIds }) => {
  const [favourites, setFavourites] = useState([]);

  useEffect(() => {
    axios
      .get(`/json/getFavourites.json`)
      .then(res => setFavourites(res.data.favourites));
  }, [setFavourites]);

  return (
    <React.Fragment>
      <AccountProducts products={favourites} />
    </React.Fragment>
  );
};

export default FavouritesContent;
