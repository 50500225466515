import { combineReducers } from 'redux';
import { doNotMissReducer } from './doNotMiss/doNotMissReducer';
import { mostPopularReducer } from './mostPopular/mostPopularReducer';
import { recommendedReducer } from './recommended/recommendedReducer';
import { categoriesReducer } from './categories/categoriesReducer';
import { filterReducer } from './filter/filterReducer';
import { favoritsReducers } from './favorits/favoritsReducers';
import { personalAccountReducer } from './personalAccount/personalAccountReducer';
import { businessAccountReducers } from './businessAccount/businessAccountReducers';

const rootReducer = combineReducers({
  doNotMissReducer,
  mostPopularReducer,
  recommendedReducer,
  categoriesReducer,
  filterReducer,
  favoritsReducers,
  personalAccountReducer,
  businessAccountReducers
});

export default rootReducer;
