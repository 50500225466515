import React from 'react';
import { Switch, Route } from 'react-router';
import { routes } from '../../routes';

import './Main.scss';

const Main = () => {
  return (
    <main className={'main'}>
      <div className="container">
        <Switch>
          {routes.defaultRoutes.map(item => (
            <Route
              key={item.id}
              path={item.path}
              exact={item.exact}
              children={<item.component />}
            />
          ))}
        </Switch>
      </div>
    </main>
  );
};

export default Main;
