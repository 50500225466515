import React, { useEffect, useState } from 'react';
import InputBlock from './InputBlock';
import './SettingsContent.scss';
import axios from 'axios';

const SettingsContent = () => {
  const [user, setUser] = useState({
    oldPasswordValue: '',
    oldPassword: '',
    confirmOldPass: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  const [validate, setValidate] = useState({
    validateNewPass: null,
    validateConfirmNewPass: null
  });

  useEffect(() => {
    axios.get(`/json/getUserInfo.json`).then(res =>
      setUser(state => {
        return {
          ...state,
          oldPasswordValue: res.data.user.password
        };
      })
    );
  }, [setUser]);

  const validInfo = (userObj, validObj) => {
    const userArr = Object.values(userObj);
    const validArr = Object.values(validObj);
    return (
      userObj.oldPasswordValue === userObj.oldPassword &&
      userObj.confirmOldPass === userObj.oldPassword &&
      userObj.newPassword === userObj.confirmNewPassword &&
      !userArr.includes('') &&
      !validArr.includes(false)
    );
  };

  const getError = (userObj, validObj) => {
    let error = null;

    if (
      userObj.oldPasswordValue !== userObj.oldPassword &&
      userObj.oldPassword !== ''
    ) {
      error = 'Incorrect old password or old passwords do not match';
    }

    if (
      userObj.oldPasswordValue === userObj.oldPassword &&
      userObj.oldPassword !== ''
    ) {
      error = 'Old password is correct, confirm it';
    }

    if (
      userObj.confirmOldPass === userObj.oldPassword &&
      userObj.oldPassword !== ''
    ) {
      error = 'Old passwords match';
    }

    if (
      userObj.newPassword === userObj.confirmNewPassword &&
      validObj.validateNewPass === true &&
      validObj.validateConfirmNewPass === true
    ) {
      error = 'New passwords match';
    }

    return error;
  };

  return (
    <div className="settings-content">
      <p className="settings-content__title">Edit password</p>
      <InputBlock
        user={user}
        setUser={setUser}
        setValidate={setValidate}
        validate={validate}
      />
      <div className="btn-block">
        <button
          className={
            validInfo(user, validate)
              ? 'settings-content__btn'
              : 'settings-content__btn disabled'
          }
          onClick={() => console.log(user)}
        >
          Save changes
        </button>
        <span className="settings-content__text-error">
          {getError(user, validate)}
        </span>
      </div>
    </div>
  );
};

export default SettingsContent;
