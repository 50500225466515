import React, { useState } from 'react';
import './FaqsSupportSection.scss';

const faqsSupportItems = [
  {
    id: '1',
    itemImg: `${require('../../images/section-logo.png')}`,
    questionTitle: 'Question goes here',
    questionText: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis 
    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    `
  },
  {
    id: '2',
    itemImg: `${require('../../images/section-logo.png')}`,
    questionTitle: 'Question goes here',
    questionText: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis 
    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    `
  },
  {
    id: '3',
    itemImg: `${require('../../images/section-logo.png')}`,
    questionTitle: 'Question goes here',
    questionText: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis 
    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    `
  },
  {
    id: '4',
    itemImg: `${require('../../images/section-logo.png')}`,
    questionTitle: 'Question goes here',
    questionText: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis 
    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    `
  },
  {
    id: '5',
    itemImg: `${require('../../images/section-logo.png')}`,
    questionTitle: 'Question goes here',
    questionText: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis 
    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    `
  },
  {
    id: '6',
    itemImg: `${require('../../images/section-logo.png')}`,
    questionTitle: 'Question goes here',
    questionText: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis 
    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    `
  }
];

const FaqsSupportSection = () => {
  const [itemsIds, setItemsIds] = useState([]);

  const clickHandler = (id, arr) => {
    if (arr.includes(id)) {
      setItemsIds([...itemsIds.filter(item => item !== id)]);
    } else {
      setItemsIds(state => {
        return [...state, id];
      });
    }
  };

  return (
    <ul className="faqs-support">
      {faqsSupportItems.map(item => {
        return (
          <li
            className={
              itemsIds.includes(item.id)
                ? 'faqs-support__item grow-item'
                : 'faqs-support__item'
            }
            key={item.id}
          >
            <div className="question-block">
              <img
                src={item.itemImg}
                alt="faq-img"
                className="question-block__img"
              />
              <div className="question-info-block">
                <p className="question-info-block__title">
                  {item.questionTitle}
                </p>
                <p
                  className={
                    itemsIds.includes(item.id)
                      ? 'question-info-block__text show-content'
                      : 'question-info-block__text'
                  }
                >
                  {item.questionText}
                </p>
              </div>
            </div>
            <div
              className={
                itemsIds.includes(item.id)
                  ? 'functionality-block active-state'
                  : 'functionality-block'
              }
              onClick={() => clickHandler(item.id, itemsIds)}
            >
              <span
                className={
                  itemsIds.includes(item.id)
                    ? 'functionality-block__text rotate'
                    : 'functionality-block__text'
                }
              >
                +
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default FaqsSupportSection;
