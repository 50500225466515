import React from 'react';
import CustomInput from '../Common/CustomInput';
import './AddressesInputBlock.scss';
import Select from 'react-select';
import PropTypes from 'prop-types';

const cityData = [
  {
    id: '1',
    value: 'Sydney',
    label: 'Sydney'
  },
  {
    id: '2',
    value: 'Melbourne',
    label: 'Melbourne'
  },
  {
    id: '3',
    value: 'Brisbane',
    label: 'Brisbane'
  },
  {
    id: '4',
    value: 'Perth',
    label: 'Perth'
  },
  {
    id: '5',
    value: 'Adelaide',
    label: 'Adelaide'
  }
];

const StateProvinceData = [
  {
    id: '6',
    value: 'New South Wales',
    label: 'New South Wales'
  },
  {
    id: '7',
    value: 'Victoria',
    label: 'Victoria'
  },
  {
    id: '8',
    value: 'Queensland',
    label: 'Queensland'
  },
  {
    id: '9',
    value: 'Western Australia',
    label: 'Western Australia'
  },
  {
    id: '10',
    value: 'South Australia',
    label: 'South Australia'
  }
];

const countryData = [
  {
    id: '11',
    value: 'Australia',
    label: 'Australia'
  }
];

const AddressesInputBlock = ({ addressFields, setAddressFields }) => {
  const textStreetHandler = event => {
    event.persist();
    setAddressFields(state => {
      return {
        ...state,
        streetAddress: event.target.value
      };
    });
  };

  const selectCityHandler = selectedCity => {
    setAddressFields(state => {
      return {
        ...state,
        selectedCity: selectedCity
      };
    });
  };

  const selectStateProvinceHandler = selectedStateProvince => {
    setAddressFields(state => {
      return {
        ...state,
        selectedStateProvince: selectedStateProvince
      };
    });
  };

  const selectedCountryHandler = selectedCountry => {
    setAddressFields(state => {
      return {
        ...state,
        selectedCountry: selectedCountry
      };
    });
  };

  return (
    <React.Fragment>
      <CustomInput
        value={addressFields.streetAddress}
        handleChange={textStreetHandler}
        type="text"
        label="Street address"
        classForInput="addresses-input__item"
        required
      />
      <div className="addresses-select__item">
        <p className="addresses-select__item-title">City</p>
        <Select
          value={addressFields.selectedCity}
          options={cityData}
          onChange={selectCityHandler}
          placeholder="Select"
        />
        <i className="icon-dropdown" />
      </div>
      <div className="addresses-select__item">
        <p className="addresses-select__item-title">State/Province</p>
        <Select
          value={addressFields.selectedStateProvince}
          options={StateProvinceData}
          onChange={selectStateProvinceHandler}
          placeholder="Select"
        />
        <i className="icon-dropdown" />
      </div>
      <div className="addresses-select__item">
        <p className="addresses-select__item-title">Country</p>
        <Select
          value={addressFields.selectedCountry}
          options={countryData}
          onChange={selectedCountryHandler}
          placeholder="Select"
        />
        <i className="icon-dropdown" />
      </div>
    </React.Fragment>
  );
};

AddressesInputBlock.propTypes = {
  setAddressFields: PropTypes.func,
  addressFields: PropTypes.object
};

export default AddressesInputBlock;
