import React from 'react';
import './MyAccountSection.scss';
import AccountMenu from '../../components/Account/AccountMenu';
import AccountContent from '../../components/Account/AccountContent';

const MyAccountSection = () => {
  return (
    <div className="my-account">
      <AccountMenu />
      <AccountContent />
    </div>
  );
};

export default MyAccountSection;
