import React from 'react';
import './PostSection.scss';
import CustomTitle from '../../components/Common/CustomTitle';
import PricingContent from '../../components/Pricing/PricingContent';

const PostSection = () => {
  return (
    <div className="post">
      <CustomTitle
        title="Post an ad"
        subtitle="Select your package"
        className="post__title"
        classNameForSubtitle="post__sub-title"
      />
      <div className="post-packages">
        <PricingContent />
      </div>
    </div>
  );
};

export default PostSection;
