import React from 'react';
import ContactInputBlocks from '../../components/Contact/ContactInputBlocks';
import './ContactSection.scss';

const listItems = [
  {
    id: '1',
    img: require('../../images/section-logo.png'),
    title: 'Phone number',
    textInfo: '(2) 346312547548'
  },
  {
    id: '2',
    img: require('../../images/section-logo.png'),
    title: 'Address',
    textInfo: 'Address goes here'
  },
  {
    id: '3',
    img: require('../../images/section-logo.png'),
    title: 'Email address',
    textInfo: 'info@gmail.com'
  }
];

const mapImg = require('../../images/map-photo.png');

const mapSelectionImg = require('../../images/section-logo.png');

const ContactSection = () => {
  return (
    <div className="contact-block">
      <div className="contact-content">
        <ul className="content-header-list">
          {listItems.map(item => {
            return (
              <li className="content-header-list__item" key={item.id}>
                <img
                  src={item.img}
                  alt="Section logo"
                  className="content-header-list__img"
                />
                <div className="content-header-list-text-block">
                  <p className="content-header-list__title">{item.title}</p>
                  <p className="content-header-list__text">{item.textInfo}</p>
                </div>
              </li>
            );
          })}
        </ul>
        <div className="content-bottom">
          <ContactInputBlocks />
          <button className="contact__btn">Send</button>
        </div>
      </div>
      <div className="contact-content-map">
        <img src={mapImg} alt="Map" className="contact-content-map__img" />
        <img
          src={mapSelectionImg}
          alt="Map selection"
          className="contact-content-map__selection-img"
        />
      </div>
    </div>
  );
};

export default ContactSection;
