import React from 'react';
import Breadcrumbs from '../../routes';
import ContactSection from '../../sections/Contact/ContactSection';

const ContactPage = () => {
  return (
    <React.Fragment>
      <Breadcrumbs />
      <ContactSection />
    </React.Fragment>
  );
};

export default ContactPage;
