import { connect } from 'react-redux';
import {
  setPersonalAccountData,
  setValidFirstName,
  setInvalidFirstName,
  setValidLastName,
  setInvalidLastName,
  setValidEmail,
  setInvalidEmail,
  setAvatar
} from '../../store/personalAccount/personalAccountActions';
import PersonalAccount from './PersonalAccount';

const mapStateToProps = state => {
  return {
    personalAccountData: state.personalAccountReducer.personalAccountData,
    validation: state.personalAccountReducer.validation
  };
};

const mapDispatchToProps = {
  setPersonalAccountData,
  setValidFirstName,
  setInvalidFirstName,
  setValidLastName,
  setInvalidLastName,
  setValidEmail,
  setInvalidEmail,
  setAvatar
};

const PersonalAccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalAccount);

export default PersonalAccountContainer;
