import React from 'react';
import CustomTitle from '../../components/Common/CustomTitle';
import PricingContent from '../../components/Pricing/PricingContent';
import './PricingSubpage.scss';

const PricingSubpage = () => {
  return (
    <div className="pricing">
      <CustomTitle title="Pricing" className="pricing__title" />
      <PricingContent />
    </div>
  );
};

export default PricingSubpage;
