import React from 'react';
import './InputPersonalBlock.scss';
import CustomInput from '../Common/CustomInput';
import PropTypes from 'prop-types';
import { nameRegexp, emailRegexp } from '../../regExp';

const InputPersonalBlock = ({
  personalAccountData,
  validation,
  setValidFirstName,
  setInvalidFirstName,
  setValidLastName,
  setInvalidLastName,
  setValidEmail,
  setInvalidEmail
}) => {
  const handleChangeFrsName = event => {
    event.persist();
    if (nameRegexp.test(event.target.value)) {
      setValidFirstName(event.target.value);
    } else {
      setInvalidFirstName(event.target.value);
    }
  };

  const handleChangeLastName = event => {
    event.persist();
    if (nameRegexp.test(event.target.value)) {
      setValidLastName(event.target.value);
    } else {
      setInvalidLastName(event.target.value);
    }
  };

  const handleChangeEmail = event => {
    event.persist();
    if (emailRegexp.test(event.target.value)) {
      setValidEmail(event.target.value);
    } else {
      setInvalidEmail(event.target.value);
    }
  };

  return (
    <div className="personal-block-input-section">
      <CustomInput
        value={personalAccountData.firstName}
        handleChange={handleChangeFrsName}
        label="First name"
        type="text"
        classForWrapper="personal-block-input-wrapper"
        classForInput={
          validation.firstName
            ? 'personal-block-input__item valid'
            : 'personal-block-input__item invalid'
        }
      />
      <CustomInput
        value={personalAccountData.lastName}
        handleChange={handleChangeLastName}
        label="Last name"
        type="text"
        classForWrapper="personal-block-input-wrapper"
        classForInput={
          validation.lastName
            ? 'personal-block-input__item valid'
            : 'personal-block-input__item invalid'
        }
      />
      <CustomInput
        value={personalAccountData.email}
        handleChange={handleChangeEmail}
        label="Email address"
        type="email"
        classForWrapper="personal-block-input-wrapper"
        classForInput={
          validation.email
            ? 'personal-block-input__item valid'
            : 'personal-block-input__item invalid'
        }
      />
    </div>
  );
};

InputPersonalBlock.propTypes = {
  personalAccountData: PropTypes.object,
  validation: PropTypes.object,
  setValidFirstName: PropTypes.func,
  setInvalidFirstName: PropTypes.func,
  setValidLastName: PropTypes.func,
  setInvalidLastName: PropTypes.func,
  setValidEmail: PropTypes.func,
  setInvalidEmail: PropTypes.func
};

export default InputPersonalBlock;
