import React, { useState } from 'react';
import './CreatePasswordSection.scss';
import CustomTitle from '../../components/Common/CustomTitle';
import CustomInput from '../../components/Common/CustomInput';
import { passRegexp } from '../../regExp';

const CreatePasswordSection = () => {
  const [newPass, setNewPass] = useState({
    password: '',
    confirmPassword: '',
    validatePass: null,
    validateConfirmPass: null
  });

  const validInfo = obj => {
    const arr = Object.values(obj);
    return (
      obj.password === obj.confirmPassword &&
      !arr.includes('') &&
      !arr.includes(false)
    );
  };

  const changeNewPassHandler = event => {
    event.persist();
    if (passRegexp.test(event.target.value)) {
      setNewPass(state => {
        return {
          ...state,
          password: event.target.value,
          validatePass: true
        };
      });
    } else {
      setNewPass(state => {
        return {
          ...state,
          password: event.target.value,
          validatePass: false
        };
      });
    }
  };

  const changeConfirmPassHandler = event => {
    event.persist();
    if (passRegexp.test(event.target.value)) {
      setNewPass(state => {
        return {
          ...state,
          confirmPassword: event.target.value,
          validateConfirmPass: true
        };
      });
    } else {
      setNewPass(state => {
        return {
          ...state,
          confirmPassword: event.target.value,
          validateConfirmPass: false
        };
      });
    }
  };

  return (
    <div className="create-pass-block">
      <CustomTitle
        title="Create a new password"
        subtitle="Enter your email address and we will send you an email to reset your password"
      />
      <form className="create-pass-block-form">
        <div className="create-pass-input-section">
          <CustomInput
            type="password"
            placeholder="Create new password"
            handleChange={changeNewPassHandler}
            classForWrapper="create-pass-block-wrapper"
            classForInput={newPass.validatePass ? 'valid' : 'invalid'}
          />
          <CustomInput
            type="password"
            placeholder="Confirm new password"
            handleChange={changeConfirmPassHandler}
            classForWrapper="create-pass-block-wrapper"
            classForInput={newPass.validateConfirmPass ? 'valid' : 'invalid'}
          />
        </div>
        <button
          className={
            validInfo(newPass)
              ? 'create-pass-block__btn'
              : 'create-pass-block__btn disabled'
          }
          onClick={() => console.log(newPass)}
        >
          Save password
        </button>
      </form>
    </div>
  );
};

export default CreatePasswordSection;
