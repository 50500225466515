import React, { useState } from 'react';
import './SignInSection.scss';
import InputBlock from '../../components/SignIn/InputBlock';
import BottomBlock from '../../components/SignIn/BottomBlock';
import FacebookBlock from '../../components/SignUp/FacebookBlock';
import GoogleBlock from '../../components/SignUp/GoogleBlock';
import CustomTitle from '../../components/Common/CustomTitle';

const SignInSection = () => {
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
    validate: {
      email: null,
      password: null
    }
  });

  const submitHandler = event => {
    event.preventDefault();
  };

  return (
    <div className="login-in-account">
      <div className="login-in-account-wrapper">
        <CustomTitle title="Welcome back" />
        <form onSubmit={submitHandler}>
          <InputBlock userInfo={userInfo} setUserInfo={setUserInfo} />
          <BottomBlock userInfo={userInfo} />
        </form>
      </div>
      <div className="login-in-account-social-network-wrapper">
        <CustomTitle title="Or login using" />
        <FacebookBlock />
        <GoogleBlock />
      </div>
    </div>
  );
};

export default SignInSection;
