import React from 'react';
import Breadcrumbs from '../../routes';
import PostSection from '../../sections/Post/PostSection';

const PostPage = () => {
  return (
    <React.Fragment>
      <Breadcrumbs />
      <PostSection />
    </React.Fragment>
  );
};

export default PostPage;
