import React from 'react';
import './CheckboxBlock.scss';
import CustomCheckbox from '../../components/Common/CustomCheckbox';
import PropTypes from 'prop-types';

const CheckboxBlock = ({ profile, setUpProfile }) => {
  const SupplierHandler = () => {
    setUpProfile(state => {
      return {
        ...state,
        checkedSupplier: {
          ...state.checkedSupplier,
          value: !state.checkedSupplier.value,
          status: 'active'
        },
        checkedBuyer: {
          ...state.checkedBuyer,
          value: false,
          status: 'disabled'
        },
        checkedBoth: {
          ...state.checkedBoth,
          value: false,
          status: 'disabled'
        }
      };
    });
  };

  const BuyerHandler = () => {
    setUpProfile(state => {
      return {
        ...state,
        checkedSupplier: {
          ...state.checkedSupplier,
          value: false,
          status: 'disabled'
        },
        checkedBuyer: {
          ...state.checkedBuyer,
          value: !state.checkedBuyer.value,
          status: 'active'
        },
        checkedBoth: {
          ...state.checkedBoth,
          value: false,
          status: 'disabled'
        }
      };
    });
  };

  const BothHandler = () => {
    setUpProfile(state => {
      return {
        ...state,
        checkedSupplier: {
          ...state.checkedSupplier,
          value: false,
          status: 'disabled'
        },
        checkedBuyer: {
          ...state.checkedBuyer,
          value: false,
          status: 'disabled'
        },
        checkedBoth: {
          ...state.checkedBoth,
          value: !state.checkedBoth.value,
          status: 'active'
        }
      };
    });
  };

  return (
    <div className="checkbox-block-set-up-profile">
      <p className="checkbox-block-set-up-profile__title">I am</p>
      <ul className="checkbox-block-set-up-profile-list">
        <li className="checkbox-block-set-up-profile-list__item">
          <CustomCheckbox
            checked={profile.checkedSupplier.value}
            handleChange={SupplierHandler}
          />
          <label className="checkbox-block-set-up-profile-list__item-label">
            A supplier
          </label>
        </li>
        <li className="checkbox-block-set-up-profile-list__item">
          <CustomCheckbox
            checked={profile.checkedBuyer.value}
            handleChange={BuyerHandler}
          />
          <label className="checkbox-block-set-up-profile-list__item-label">
            A buyer
          </label>
        </li>
        <li className="checkbox-block-set-up-profile-list__item">
          <CustomCheckbox
            checked={profile.checkedBoth.value}
            handleChange={BothHandler}
          />
          <label className="checkbox-block-set-up-profile-list__item-label">
            Both
          </label>
        </li>
      </ul>
    </div>
  );
};

CheckboxBlock.propTypes = {
  profile: PropTypes.object,
  setUpProfile: PropTypes.func
};

export default CheckboxBlock;
