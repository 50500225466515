export const SET_PERSONAL_ACCOUNT_DATA = 'SET_PERSONAL_ACCOUNT_DATA';
export const SET_VALID_FIRST_NAME = 'SET_VALID_FIRST_NAME';
export const SET_INVALID_FIRST_NAME = 'SET_INVALID_FIRST_NAME';
export const SET_VALID_LAST_NAME = 'SET_VALID_LAST_NAME';
export const SET_INVALID_LAST_NAME = 'SET_INVALID_LAST_NAME';
export const SET_VALID_EMAIL = 'SET_VALID_EMAIL';
export const SET_INVALID_EMAIL = 'SET_INVALID_EMAIL';
export const SET_PERSONAL_AVATAR = 'SET_PERSONAL_AVATAR';

export const setPersonalAccountData = obj => {
  return {
    type: SET_PERSONAL_ACCOUNT_DATA,
    payload: obj
  };
};

export const setValidFirstName = str => {
  return {
    type: SET_VALID_FIRST_NAME,
    payload: str
  };
};

export const setInvalidFirstName = str => {
  return {
    type: SET_INVALID_FIRST_NAME,
    payload: str
  };
};

export const setValidLastName = str => {
  return {
    type: SET_VALID_LAST_NAME,
    payload: str
  };
};

export const setInvalidLastName = str => {
  return {
    type: SET_INVALID_LAST_NAME,
    payload: str
  };
};

export const setValidEmail = str => {
  return {
    type: SET_VALID_EMAIL,
    payload: str
  };
};

export const setInvalidEmail = str => {
  return {
    type: SET_INVALID_EMAIL,
    payload: str
  };
};

export const setAvatar = (str, file) => {
  return {
    type: SET_PERSONAL_AVATAR,
    payload: {
      src: str,
      file: file
    }
  };
};
