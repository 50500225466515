import React from 'react';
import { NavLink } from 'react-router-dom';
import './Breadcrumbs.scss';
import PropTypes from 'prop-types';

export const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <div className="breadcrumbs">
      {breadcrumbs.map(item => {
        if (item.match.path !== item.location.pathname) {
          return (
            <React.Fragment key={item.match.path}>
              <span className="breadcrumbs__text">
                <NavLink to={item.match.url} className="breadcrumbs__link">
                  {item.breadcrumb}
                </NavLink>
              </span>
              <i className="icon-arrow-bread" />
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={item.match.path}>
              <span className="breadcrumbs__text">{item.breadcrumb}</span>
              <i className="icon-arrow-bread" />
            </React.Fragment>
          );
        }
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired
};
