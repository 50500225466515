import React from 'react';
import PropTypes from 'prop-types';
import './SectionItems.scss';

const SectionItems = ({
  sectionData,
  productsIds,
  setProductsId,
  filterProductsId
}) => {
  const clickHandler = (id, arr) => {
    if (arr.includes(id)) {
      filterProductsId(id);
    } else {
      setProductsId(id);
    }
  };

  return (
    <ul className="section-list">
      {sectionData.map(item => {
        return (
          <li className="section-list__item" key={item.id}>
            <div className="product-header">
              <img
                src={item.img}
                alt="section-img"
                className="section-list__img"
              />
              <div className="section-list__text-price">
                <div className="price-block">
                  <p className="price-block__text-dollar">$ </p>
                  {item.price}
                </div>
              </div>
            </div>
            <div className="product-footer">
              <p className="section-list__title">{item.title}</p>
              <p className="section-list__subtitle">{item.subtitle}</p>
              <div className="section-list__text-info">
                {item.info + ', ' + item.titleLink}
              </div>
              <i
                className={
                  productsIds.includes(item.id)
                    ? item.iconFill
                    : item.iconDefault
                }
                onClick={() => {
                  clickHandler(item.id, productsIds);
                }}
              />
            </div>
          </li>
        );
      })}
    </ul>
  );
};

SectionItems.propTypes = {
  sectionData: PropTypes.arrayOf(Object),
  setProductsId: PropTypes.func,
  filterProductsId: PropTypes.func,
  productsIds: PropTypes.array
};

export default SectionItems;
