import {
  SET_BUSINESS_ACCOUNT_DATA,
  SET_BUSINESS_TYPE_DATA,
  SET_SELECTED_BUSINESS_TYPE,
  SET_ADDRESS,
  SET_BUSINESS_NAME,
  SET_BUSINESS_DESCRIPTION,
  SET_BUSINESS_AVATAR
} from './businessAccountActions';

const initialState = {
  businessAccountData: {
    img: '../images/default-user.jpg',
    selectedBusinessType: null,
    businessName: '',
    businessDescription: '',
    address: ''
  },
  businessTypeData: []
};

export const businessAccountReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUSINESS_ACCOUNT_DATA:
      return {
        ...state,
        businessAccountData: action.payload
      };

    case SET_BUSINESS_TYPE_DATA:
      return {
        ...state,
        businessTypeData: [...action.payload]
      };

    case SET_BUSINESS_NAME:
      return {
        ...state,
        businessAccountData: {
          ...state.businessAccountData,
          businessName: action.payload
        }
      };

    case SET_BUSINESS_DESCRIPTION:
      return {
        ...state,
        businessAccountData: {
          ...state.businessAccountData,
          businessDescription: action.payload
        }
      };

    case SET_SELECTED_BUSINESS_TYPE:
      return {
        ...state,
        businessAccountData: {
          ...state.businessAccountData,
          selectedBusinessType: action.payload
        }
      };

    case SET_ADDRESS:
      return {
        ...state,
        businessAccountData: {
          ...state.businessAccountData,
          address: action.payload
        }
      };

    case SET_BUSINESS_AVATAR:
      return {
        ...state,
        businessAccountData: {
          ...state.businessAccountData,
          img: action.payload.src,
          imgFile: action.payload.file
        }
      };

    default:
      return state;
  }
};
