import React from 'react';
import Breadcrumbs from '../../routes';
import AboutSection from '../../sections/About/AboutSection';

const AboutPage = () => {
  return (
    <React.Fragment>
      <Breadcrumbs />
      <AboutSection />
    </React.Fragment>
  );
};

export default AboutPage;
