import React from 'react';
import Recaptcha from 'react-recaptcha';
import './CaptchaBlock.scss';
import PropTypes from 'prop-types';

const CaptchaBlock = ({ setCaptchaChecked }) => {
  const verifyCallback = res => {
    console.log('captcha response token - ' + res);
    setCaptchaChecked(state => {
      return {
        ...state,
        captchaChecked: true
      };
    });
  };

  const expiredCallback = () => {
    setCaptchaChecked(state => {
      return {
        ...state,
        captchaChecked: false
      };
    });
  };

  const onloadCallback = () => console.log('done');

  return (
    <div className="captcha-block">
      <p className="captcha__title">
        Before you proceed please complete the captcha below
      </p>
      <div className="captcha-wrapper">
        <Recaptcha
          sitekey="6LdlztoUAAAAAEttmsXGzhZoVARnRWQJKcDch3Rd"
          render="explicit"
          verifyCallback={verifyCallback}
          expiredCallback={expiredCallback}
          onloadCallback={onloadCallback}
        />
      </div>
    </div>
  );
};

CaptchaBlock.propTypes = {
  setCaptchaChecked: PropTypes.func
};

export default CaptchaBlock;
