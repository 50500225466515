import React, { useState } from 'react';
import CustomInput from '../../components/Common/CustomInput';
import CustomTitle from '../../components/Common/CustomTitle';
import './ForgotPasswordSection.scss';
import { useHistory } from 'react-router-dom';
import { emailRegexp } from '../../regExp';

const ForgotPasswordSection = () => {
  const [email, setEmail] = useState({
    email: '',
    validateEmail: null
  });

  const handleChangeEmail = event => {
    event.persist();
    if (emailRegexp.test(event.target.value)) {
      setEmail(state => {
        return {
          ...state,
          email: event.target.value,
          validateEmail: true
        };
      });
    } else {
      setEmail(state => {
        return {
          ...state,
          email: event.target.value,
          validateEmail: false
        };
      });
    }
  };

  const history = useHistory();

  const handleClick = () => {
    history.push('/create-pass');
  };

  return (
    <div className="forgot-pass-block">
      <CustomTitle
        title="Forgot your password?"
        subtitle="Enter your email address and we will send you an email to reset your password"
      />
      <form className="forgot-pass-block-form">
        <CustomInput
          type="email"
          placeholder="Email address"
          handleChange={handleChangeEmail}
          classForInput={email.validateEmail ? 'valid' : 'invalid'}
        />
        <button
          className={
            email.validateEmail
              ? 'forgot-pass-block__btn'
              : 'forgot-pass-block__btn disabled'
          }
          onClick={handleClick}
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default ForgotPasswordSection;
