import React from 'react';
import './CustomSelectLocation.scss';
import PlacesAutocomplete from 'react-places-autocomplete';

const CustomSelectLocation = ({
  placeholder,
  className,
  icon,
  required,
  setInfo,
  info,
  setReduxInfo
}) => {
  const classSelect = className
    ? className + ' select-wrapper'
    : 'select-wrapper';

  const handleSelect = async value => {
    if (setInfo) {
      setInfo(state => {
        return {
          ...state,
          address: value
        };
      });
    } else {
      setReduxInfo(value);
    }
  };

  const handleChange = address => {
    if (setInfo) {
      setInfo(state => {
        return {
          ...state,
          address: address
        };
      });
    } else {
      setReduxInfo(address);
    }
  };

  return (
    <PlacesAutocomplete
      value={info.address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <React.Fragment>
          <div className={classSelect}>
            <input
              {...getInputProps({
                placeholder: placeholder,
                className: 'custom-select-location',
                type: 'text',
                required: required
              })}
            />
            <i className={icon} />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                  >
                    <span className="autocomplete-dropdown-container__text">
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      )}
    </PlacesAutocomplete>
  );
};

export default CustomSelectLocation;
