import { connect } from 'react-redux';
import { setFilterData } from '../../store/filter/filterActions';
import { setCategoriesData } from '../../store/categories/categoriesActions';
import SearchSection from './SearchSection';

const mapStateToProps = state => {
  return {
    categoriesData: state.categoriesReducer.categoriesData
  };
};

const mapDispatchToProps = {
  setFilterData,
  setCategoriesData
};

const SearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchSection);

export default SearchContainer;
