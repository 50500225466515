import React from 'react';
import Logo from '../../components/Header/Logo';
import SideBlocks from '../../components/Header/SideBlocks';
import './HeaderSection.scss';

const HeaderSection = () => {
  return (
    <div className="logo-side-container">
      <Logo />
      <SideBlocks />
    </div>
  );
};

export default HeaderSection;
