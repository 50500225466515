import React from 'react';
import './SettingsSubpage.scss';
import CustomTitle from '../../components/Common/CustomTitle';
import SettingsContent from '../../components/Settings/SettingsContent';

const SettingsSubpage = () => {
  return (
    <div className="settings">
      <CustomTitle title="Settings" className="settings__title" />
      <SettingsContent />
    </div>
  );
};

export default SettingsSubpage;
