import React from 'react';
import Breadcrumbs from '../../routes';
import LegalSection from '../../sections/Legal/LegalSection';

const LegalPage = () => {
  return (
    <React.Fragment>
      <Breadcrumbs />
      <LegalSection />
    </React.Fragment>
  );
};

export default LegalPage;
