import React from 'react';
import Breadcrumbs from '../../routes';
import ProductItemsSection from '../../sections/ProductItems/ProductItemsSection';

const ProductItemsPage = () => {
  return (
    <React.Fragment>
      <Breadcrumbs />
      <ProductItemsSection />
    </React.Fragment>
  );
};

export default ProductItemsPage;
