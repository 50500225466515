import React from 'react';
import { Link } from 'react-router-dom';
import CustomInput from '../../components/Common/CustomInput';
import './InputBlock.scss';
import PropTypes from 'prop-types';
import { emailRegexp, passRegexp } from '../../regExp';

const InputBlock = ({ userInfo, setUserInfo }) => {
  const emailHandler = event => {
    event.persist();
    if (emailRegexp.test(event.target.value)) {
      setUserInfo(state => {
        return {
          ...state,
          email: event.target.value,
          validate: {
            ...state.validate,
            email: true
          }
        };
      });
    } else {
      setUserInfo(state => {
        return {
          ...state,
          email: event.target.value,
          validate: {
            ...state.validate,
            email: false
          }
        };
      });
    }
  };

  const passwordHandler = event => {
    event.persist();
    if (passRegexp.test(event.target.value)) {
      setUserInfo(state => {
        return {
          ...state,
          password: event.target.value,
          validate: {
            ...state.validate,
            password: true
          }
        };
      });
    } else {
      setUserInfo(state => {
        return {
          ...state,
          password: event.target.value,
          validate: {
            ...state.validate,
            password: false
          }
        };
      });
    }
  };

  return (
    <div className="input-block-sign-in">
      <CustomInput
        value={userInfo.email}
        handleChange={emailHandler}
        type="email"
        placeholder="Email address"
        classForWrapper="input-block-sign-in-wrapper"
        classForInput={userInfo.validate.email ? 'valid' : 'invalid'}
      />
      <CustomInput
        value={userInfo.password}
        handleChange={passwordHandler}
        type="password"
        placeholder="Password"
        classForWrapper="input-block-sign-in-wrapper"
        classForInput={userInfo.validate.password ? 'valid' : 'invalid'}
      />
      <Link to="/forgot-pass" className="input-block-sign-in__link">
        Forgot your password?
      </Link>
    </div>
  );
};

InputBlock.propTypes = {
  userInfo: PropTypes.object,
  setUserInfo: PropTypes.func
};

export default InputBlock;
