import React, { useEffect, useState } from 'react';
import './ProductItemsSection.scss';
import axios from 'axios';
import CustomTitle from '../../components/Common/CustomTitle';
import SectionItemsContainer from '../../components/ProductsStack/SectionItemsContainer';

const ProductItemsSection = () => {
  const [productItems, setProductItems] = useState([]);

  useEffect(() => {
    axios
      .get(`/json/getProductItems.json`)
      .then(res => setProductItems(res.data.productItems));
  }, [setProductItems]);

  return (
    <div className="product-items">
      <CustomTitle title="Products" />
      <SectionItemsContainer sectionData={productItems} />
    </div>
  );
};

export default ProductItemsSection;
