import React from 'react';
import './Downshift.scss';
import PropTypes from 'prop-types';
import useResizeAware from 'react-resize-aware';

const Downshift = ({ items, isOpen, setSearchData }) => {
  const [resizeListener, sizes] = useResizeAware();
  let downShiftClass = 'downshift';

  if (!isOpen) return null;

  if (sizes.height > 275 && sizes.height < 290) {
    downShiftClass = 'downshift';
  } else {
    downShiftClass = 'downshift overflow-hidden';
  }

  const productClickHandler = (itemId, itemValue) => {
    setSearchData(state => {
      return {
        ...state,
        selectedProduct: {
          label: itemValue,
          value: itemValue,
          id: itemId
        },
        searchText: itemValue,
        isDownshiftOpen: false
      };
    });
  };

  return (
    <div className={downShiftClass}>
      {resizeListener}
      <ul className="downshift-list">
        {items &&
          items.map(item => {
            return (
              <li
                className="downshift-list__item"
                key={item.id}
                onClick={() => productClickHandler(item.id, item.title)}
              >
                {item.title}
              </li>
            );
          })}
        {items.length === 0 ? (
          <div className="downshift-list__item-warning">Nothing Found!</div>
        ) : null}
      </ul>
    </div>
  );
};

Downshift.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  setSearchData: PropTypes.func
};

export default Downshift;
