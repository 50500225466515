import React from 'react';
import './Avatar.scss';
import PropTypes from 'prop-types';
import ReactFileReader from 'react-file-reader';

const Avatar = ({ className, img, setAvatar }) => {
  const handleFiles = files => {
    setAvatar(files.base64, files.fileList[0]);
  };

  return (
    <div className={className}>
      <p className="avatar-title">General info</p>
      <div className="avatar-block">
        <ReactFileReader handleFiles={handleFiles} base64={true}>
          <img src={img} alt="avatar" className="avatar-block__img" />
        </ReactFileReader>
      </div>
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string.isRequired,
  img: PropTypes.string,
  setAvatar: PropTypes.func
};

export default Avatar;
