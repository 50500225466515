import React from 'react';
import Select from 'react-select';
import './InputBusinessBlock.scss';
import CustomInput from '../Common/CustomInput';
import CustomSelectLocation from '../Common/CustomSelectLocation';
import CustomTextarea from '../Common/CustomTextarea';
import PropTypes from 'prop-types';

const InputBusinessBlock = ({
  businessTypeData,
  businessAccountData,
  setAddress,
  setSelectedBusinessType,
  setBusinessName,
  setBusinessDescription
}) => {
  const currentType = businessAccountData.selectedBusinessType;
  const businessNameValue = businessAccountData.businessName;
  const businessDescriptionValue = businessAccountData.businessDescription;

  const selectHandler = currentType => {
    setSelectedBusinessType(currentType);

    if (currentType !== null) {
      setTimeout(() => alert('Please add your business name.'), 500);
    }
  };

  const businessNameHandler = event => {
    event.persist();
    setBusinessName(event.target.value);
  };

  const businessDescriptionHandler = event => {
    event.persist();
    setBusinessDescription(event.target.value);
  };

  const submitHandler = event => {
    event.preventDefault();
    console.log(businessAccountData);
  };

  return (
    <form className="business-block-input-section" onSubmit={submitHandler}>
      <div className="business-block-input-section-row">
        <div className="select__item">
          <p className="select__item-title">Select business type</p>
          <Select
            value={currentType}
            placeholder="Sole trader"
            options={businessTypeData}
            onChange={selectHandler}
          />
          <i className="icon-dropdown" />
        </div>
        <CustomInput
          value={businessNameValue}
          handleChange={businessNameHandler}
          label="Add business name"
          required
          type="text"
          classForWrapper={
            currentType === null
              ? 'business-block-input-wrapper disabled'
              : 'business-block-input-wrapper'
          }
          classForInput="business-block-input__item"
        />
      </div>
      <CustomTextarea
        classForContainer="text-area__item"
        placeholder="Type here"
        label="Add description about your business"
        value={businessDescriptionValue}
        handleChange={businessDescriptionHandler}
      />
      <div className="input-location__item">
        <p className="input-location__item-title">Enter your location</p>
        <CustomSelectLocation
          placeholder="Select"
          className="business-block-input-location__item"
          icon="icon-map"
          info={businessAccountData}
          setReduxInfo={setAddress}
        />
      </div>
      <button className="business-block-input-section__btn">
        Save changes
      </button>
    </form>
  );
};

InputBusinessBlock.propTypes = {
  businessTypeData: PropTypes.array,
  setBusinessAccountData: PropTypes.func,
  businessAccountData: PropTypes.object,
  setAddress: PropTypes.func,
  setSelectedBusinessType: PropTypes.func,
  setBusinessName: PropTypes.func,
  setBusinessDescription: PropTypes.func
};

export default InputBusinessBlock;
