import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { NavLink } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import axios from 'axios';
import './SliderSection.scss';

const SliderSection = () => {
  const [sliderData, setSliderData] = useState(null);

  useEffect(() => {
    axios.get(`/json/getSliderInfo.json`).then(res =>
      setSliderData(
        <React.Fragment>
          <Carousel
            emulateTouch={true}
            showThumbs={false}
            showStatus={false}
            showArrows={true}
            className="slider"
          >
            {res.data.sliderInfo.map(item => {
              return (
                <img
                  src={item.img}
                  alt="slider-img"
                  key={item.id}
                  className="slider__img"
                />
              );
            })}
          </Carousel>
          <h1 className="dropdown-item__title">ORGANICS AT YOUR FINGERTIPS</h1>
          <NavLink to="product-items" className="dropdown-item__link">
            Check Products
          </NavLink>
        </React.Fragment>
      )
    );
  }, [setSliderData]);

  return <div className="slider-container">{sliderData}</div>;
};

export default SliderSection;
