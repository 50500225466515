import React from 'react';
import './InputBlock.scss';
import CustomInput from '../Common/CustomInput';
import PropTypes from 'prop-types';
import { nameRegexp, emailRegexp } from '../../regExp';

const InputBlock = ({ profile, setUpProfile }) => {
  const handleChangeFirstName = event => {
    event.persist();
    if (nameRegexp.test(event.target.value)) {
      setUpProfile(state => {
        return {
          ...state,
          firstName: event.target.value,
          validate: {
            ...state.validate,
            firstName: true
          }
        };
      });
    } else {
      setUpProfile(state => {
        return {
          ...state,
          firstName: event.target.value,
          validate: {
            ...state.validate,
            firstName: false
          }
        };
      });
    }
  };

  const handleChangeSecondName = event => {
    event.persist();
    if (nameRegexp.test(event.target.value)) {
      setUpProfile(state => {
        return {
          ...state,
          secondName: event.target.value,
          validate: {
            ...state.validate,
            secondName: true
          }
        };
      });
    } else {
      setUpProfile(state => {
        return {
          ...state,
          secondName: event.target.value,
          validate: {
            ...state.validate,
            secondName: false
          }
        };
      });
    }
  };

  const handleChangeEmail = event => {
    event.persist();
    if (emailRegexp.test(event.target.value)) {
      setUpProfile(state => {
        return {
          ...state,
          email: event.target.value,
          validate: {
            ...state.validate,
            email: true
          }
        };
      });
    } else {
      setUpProfile(state => {
        return {
          ...state,
          email: event.target.value,
          validate: {
            ...state.validate,
            email: false
          }
        };
      });
    }
  };

  return (
    <div className="input-block-set-up-profile">
      <CustomInput
        value={profile.firstName}
        handleChange={handleChangeFirstName}
        type="text"
        placeholder="First name"
        classForWrapper="input-block-set-up-profile-wrapper"
        classForInput={profile.validate.firstName ? 'valid' : 'invalid'}
      />
      <CustomInput
        value={profile.secondName}
        handleChange={handleChangeSecondName}
        type="text"
        placeholder="Last name"
        classForWrapper="input-block-set-up-profile-wrapper"
        classForInput={profile.validate.secondName ? 'valid' : 'invalid'}
      />
      <CustomInput
        value={profile.email}
        handleChange={handleChangeEmail}
        type="email"
        placeholder="Email address"
        classForWrapper="input-block-set-up-profile-wrapper"
        classForInput={profile.validate.email ? 'valid' : 'invalid'}
      />
    </div>
  );
};

InputBlock.propTypes = {
  profile: PropTypes.object,
  setUpProfile: PropTypes.func
};

export default InputBlock;
