import React from 'react';
import './InputBlock.scss';
import CustomInput from '../Common/CustomInput';
import PropTypes from 'prop-types';
import { passRegexp } from '../../regExp';

const InputBlock = ({ user, setUser, setValidate, validate }) => {
  const oldPassHandler = event => {
    event.persist();
    setUser(state => {
      return {
        ...state,
        oldPassword: event.target.value
      };
    });
  };

  const oldPassConfirmHandler = event => {
    event.persist();
    setUser(state => {
      return {
        ...state,
        confirmOldPass: event.target.value
      };
    });
  };

  const newPassHandler = event => {
    event.persist();
    setUser(state => {
      return {
        ...state,
        newPassword: event.target.value
      };
    });

    if (passRegexp.test(event.target.value)) {
      setValidate(state => {
        return {
          ...state,
          validateNewPass: true
        };
      });
    } else {
      setValidate(state => {
        return {
          ...state,
          validateNewPass: false
        };
      });
    }
  };

  const newPassConfirmHandler = event => {
    event.persist();
    setUser(state => {
      return {
        ...state,
        confirmNewPassword: event.target.value
      };
    });

    if (passRegexp.test(event.target.value)) {
      setValidate(state => {
        return {
          ...state,
          validateConfirmNewPass: true
        };
      });
    } else {
      setValidate(state => {
        return {
          ...state,
          validateConfirmNewPass: false
        };
      });
    }
  };

  return (
    <div className="input-blocks">
      <CustomInput
        value={user.oldPassword}
        handleChange={oldPassHandler}
        label="Old password"
        type="password"
        classForWrapper="settings-input-wrapper"
        classForInput={
          user.oldPasswordValue === user.oldPassword && user.oldPassword !== ''
            ? 'settings-input__item valid'
            : 'settings-input__item invalid'
        }
      />
      <CustomInput
        value={user.confirmOldPass}
        handleChange={oldPassConfirmHandler}
        label="Confirm old password"
        type="password"
        classForWrapper="settings-input-wrapper"
        classForInput={
          user.confirmOldPass === user.oldPassword && user.oldPassword !== ''
            ? 'settings-input__item valid'
            : 'settings-input__item invalid'
        }
      />
      <CustomInput
        value={user.newPassword}
        handleChange={newPassHandler}
        label="Create new password"
        type="password"
        classForWrapper="settings-input-wrapper"
        classForInput={
          validate.validateNewPass
            ? 'settings-input__item valid'
            : 'settings-input__item invalid'
        }
      />
      <CustomInput
        value={user.confirmNewPassword}
        handleChange={newPassConfirmHandler}
        label="Confirm new password"
        type="password"
        classForWrapper="settings-input-wrapper"
        classForInput={
          validate.validateConfirmNewPass
            ? 'settings-input__item valid'
            : 'settings-input__item invalid'
        }
      />
    </div>
  );
};

InputBlock.propTypes = {
  setUser: PropTypes.func,
  user: PropTypes.object,
  validate: PropTypes.object,
  setValidate: PropTypes.func
};

export default InputBlock;
