import React from 'react';
import './AboutSection.scss';

const aboutItems = [
  {
    id: '1',
    lgImage: `${require('../../images/about-us-lg-photo1.jpg')}`,
    smImage: `${require('../../images/about-us-sm-photo.png')}`,
    title: 'Who we are',
    description: `
    Yse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi 
    ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim 
    incididunt velit tempor irure mollit voluptate consequat commodo.Product description, 
    dolor esse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi 
    ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt
     velit tempor irure mollit voluptate consequat commodo.Product description, dolor esse eu minim 
     incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit 
     cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure 
     mollit voluptate consequat commodo.`
  },
  {
    id: '2',
    lgImage: `${require('../../images/about-us-lg-photo2.jpg')}`,
    smImage: `${require('../../images/about-us-sm-photo.png')}`,
    title: 'What we do',
    description: `
    Yse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi 
    ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim 
    incididunt velit tempor irure mollit voluptate consequat commodo.Product description, 
    dolor esse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi 
    ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt
     velit tempor irure mollit voluptate consequat commodo.Product description, dolor esse eu minim 
     incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi ex aute elit velit 
     cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt velit tempor irure 
     mollit voluptate consequat commodo.`
  }
];

const AboutSection = () => {
  return (
    <div className="about">
      <ul className="about-list">
        {aboutItems.map(item => {
          return (
            <li className="about-list__item" key={item.id}>
              <div className="img-block">
                <img
                  src={item.lgImage}
                  alt="Main"
                  className="img-block__lg-image"
                />
                <img
                  src={item.smImage}
                  alt="Logo organic"
                  className="img-block__sm-image"
                />
              </div>
              <div className="description-block">
                <h3 className="description__title">{item.title}</h3>
                <p className="description__text">{item.description}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AboutSection;
