import React from 'react';
import Breadcrumbs from '../../routes';
import FaqsSupportSection from '../../sections/Faqs&Support/FaqsSupportSection';

const FaqsSupportPage = () => {
  return (
    <React.Fragment>
      <Breadcrumbs />
      <FaqsSupportSection />
    </React.Fragment>
  );
};

export default FaqsSupportPage;
