import React from 'react';
import FacebookLogin from 'react-facebook-login';
import './FacebookBlock.scss';

const FacebookBlock = () => {
  const responseFacebook = response => {
    console.log(response);
  };

  return (
    <div className="facebook-block">
      <FacebookLogin
        appId="842410689505479"
        autoLoad={false}
        fields="name,email,picture"
        textButton="Sign in with Facebook"
        icon="icon-facebook facebook-form"
        cssClass="custom-facebook-button-class"
        callback={responseFacebook}
      />
    </div>
  );
};

export default FacebookBlock;
