import React from 'react';
import './CustomInput.scss';
import PropTypes from 'prop-types';

const CustomInput = ({
  type,
  value,
  handleChange,
  placeholder,
  classForInput,
  classForWrapper,
  label,
  required
}) => {
  if (placeholder && !label) {
    return (
      <div
        className={classForWrapper ? classForWrapper + ' wrapper' : 'wrapper'}
      >
        <input
          required={required ? required : null}
          type={type}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          className={
            classForInput ? classForInput + ' custom-input' : 'custom-input'
          }
        />
      </div>
    );
  }

  if (label) {
    return (
      <div
        className={classForWrapper ? classForWrapper + ' wrapper' : 'wrapper'}
      >
        <input
          required={required ? required : null}
          type={type}
          value={value}
          onChange={handleChange}
          className={
            classForInput ? classForInput + ' custom-input' : 'custom-input'
          }
        />
        <label className="label-for-input">{label}</label>
      </div>
    );
  }
};

CustomInput.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  classForInput: PropTypes.string,
  classForWrapper: PropTypes.string,
  icon: PropTypes.string
};

export default CustomInput;
