import React from 'react';
import { Input } from 'semantic-ui-react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import 'semantic-ui-css/components/input.css';
import './SearchItems.scss';
import Downshift from '../Downshift/Downshift';

const SearchItems = ({
  title,
  btnTitle,
  placeholderTitle,
  icon,
  categoriesData,
  searchData,
  setSearchData,
  setFilterData,
  categoryClass,
  subcategoryClass,
  inputClass,
  btnClass,
  formInputClass,
  btnTextClass,
  setSelectedItemsHandler
}) => {
  let content;

  const categoryVal = searchData.selectedCategory;
  const subcategoryVal = searchData.selectedSubcategory;
  const searchVal = searchData.searchText;
  const selectedProduct = searchData.selectedProduct;

  const sortedData = arr => {
    return arr.map(item => {
      return {
        label: item.text,
        value: item.text,
        id: item.id
      };
    });
  };

  const showDownshift = () => {
    setSearchData(state => {
      return {
        ...state,
        isDownshiftOpen: true
      };
    });
  };

  const hideDownshift = () => {
    setSearchData(state => {
      return {
        ...state,
        isDownshiftOpen: false
      };
    });
  };

  const searchProducts = keyword => {
    const products = searchData.products;
    keyword = RegExp.escape(keyword.toLowerCase());
    const searchRegexp = `[A-Za-z.\\s]*${keyword}[A-Za-z.\\s]*`;
    const matchRegexp = new RegExp(searchRegexp);
    const foundProducts = products.filter(item =>
      matchRegexp.test(item.title.toLowerCase())
    );

    setSearchData(state => {
      return {
        ...state,
        foundProducts: foundProducts
      };
    });
  };

  const categoryHandler = categoryVal => {
    const resultCategory = categoriesData.filter(
      item => item.text === categoryVal.value
    );
    const resultSubcategories = resultCategory[0].subCategory;
    if (searchData.selectedSubcategory === null) {
      setSearchData(state => {
        return {
          ...state,
          selectedCategory: categoryVal,
          subcategories: resultSubcategories
        };
      });
    } else {
      setSearchData(state => {
        return {
          ...state,
          selectedCategory: categoryVal,
          subcategories: resultSubcategories,
          selectedSubcategory: null
        };
      });
    }
  };

  const subcategoryHandler = subcategoryVal => {
    setSearchData(state => {
      return {
        ...state,
        selectedSubcategory: subcategoryVal
      };
    });
  };

  const searchHandler = (event, data) => {
    setSearchData(state => {
      return {
        ...state,
        searchText: data.value
      };
    });

    searchProducts(data.value);

    if (data.value !== '') {
      showDownshift();
    } else {
      hideDownshift();
    }
  };

  if (title) {
    content = (
      <div className="search-container__items title">
        <h4>{title}</h4>
      </div>
    );
  }
  if (placeholderTitle === 'Select a category') {
    content = (
      <div
        className={
          categoryClass
            ? 'search-container__items ' + categoryClass
            : 'search-container__items select'
        }
      >
        <Select
          placeholder={placeholderTitle}
          options={sortedData(categoriesData)}
          onChange={categoryHandler}
          value={categoryVal}
        />
        <i className="icon-dropdown" />
      </div>
    );
  }

  if (placeholderTitle === 'Select sub-category') {
    content = (
      <div
        className={
          subcategoryClass
            ? 'search-container__items ' + subcategoryClass
            : 'search-container__items select'
        }
      >
        <Select
          placeholder={placeholderTitle}
          options={
            searchData.subcategories !== null
              ? sortedData(searchData.subcategories)
              : null
          }
          onChange={subcategoryHandler}
          isDisabled={searchData.subcategories === null}
          value={subcategoryVal}
        />
        <i className="icon-dropdown" />
      </div>
    );
  }

  if (btnTitle && icon) {
    content = (
      <div className="search-container__items input-text">
        <Input
          placeholder={placeholderTitle}
          className="search-container-form__input"
          value={searchVal}
          onChange={searchHandler}
        />
        <button
          className={
            categoryVal === null && searchVal === ''
              ? 'search-container-form__btn disabled'
              : 'search-container-form__btn'
          }
          type="button"
          onClick={() =>
            setFilterData(categoryVal, subcategoryVal, selectedProduct)
          }
        >
          <i className={icon} />
          <span className="search-container-form__text">{btnTitle}</span>
        </button>
        <Downshift
          isOpen={searchData.isDownshiftOpen}
          items={searchData.foundProducts}
          setSearchData={setSearchData}
        />
      </div>
    );
  }

  if (btnTitle && !icon) {
    content = (
      <div className={'search-container__items ' + inputClass}>
        <Input
          placeholder={placeholderTitle}
          className={
            formInputClass ? formInputClass : 'search-container-form__input'
          }
          value={searchVal}
          onChange={searchHandler}
        />
        <button
          className={
            categoryVal === null && searchVal === ''
              ? 'search-container-form__btn disabled ' + btnClass
              : 'search-container-form__btn ' + btnClass
          }
          type="button"
          onClick={() =>
            setSelectedItemsHandler(
              categoryVal,
              subcategoryVal,
              searchVal,
              selectedProduct
            )
          }
          disabled={categoryVal === null && searchVal === ''}
        >
          <span
            className={
              btnTextClass ? btnTextClass : 'search-container-form__text'
            }
          >
            {btnTitle}
          </span>
        </button>
        <Downshift
          isOpen={searchData.isDownshiftOpen}
          items={searchData.foundProducts}
          setSearchData={setSearchData}
        />
      </div>
    );
  }

  return <React.Fragment>{content}</React.Fragment>;
};

SearchItems.propTypes = {
  title: PropTypes.string,
  btnTitle: PropTypes.string,
  placeholderTitle: PropTypes.string,
  searchData: PropTypes.object,
  setFilterData: PropTypes.func,
  setSearchData: PropTypes.func,
  categoriesData: PropTypes.arrayOf(Object),
  icon: PropTypes.string,
  categoryClass: PropTypes.string,
  subcategoryClass: PropTypes.string,
  inputClass: PropTypes.string,
  formInputClass: PropTypes.string,
  btnClass: PropTypes.string
};

export default SearchItems;
