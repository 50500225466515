import React from 'react';
import './CustomTitle.scss';
import PropTypes from 'prop-types';

const CustomTitle = ({ title, subtitle, className, classNameForSubtitle }) => {
  if (subtitle) {
    return (
      <React.Fragment>
        <h2
          className={className ? className + ' custom-title' : 'custom-title'}
        >
          {title}
        </h2>
        <p
          className={
            classNameForSubtitle
              ? classNameForSubtitle + ' custom-text'
              : 'custom-text'
          }
        >
          {subtitle}
        </p>
      </React.Fragment>
    );
  } else {
    return (
      <h2 className={className ? className + ' custom-title' : 'custom-title'}>
        {title}
      </h2>
    );
  }
};

CustomTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  classNameForSubtitle: PropTypes.string
};

export default CustomTitle;
