import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from '../../routes';
import './AccountContent.scss';

const AccountContent = () => {
  return (
    <div className="account-content">
      <Switch>
        {routes.nestedRoutes.myAccount.map(item => (
          <Route
            key={item.id}
            path={item.path}
            exact={item.exact}
            children={<item.component />}
          />
        ))}
      </Switch>
    </div>
  );
};

export default AccountContent;
