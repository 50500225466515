import React, { useState } from 'react';
import './SignUpSection.scss';
import InputBlock from '../../components/SignUp/InputBlock';
import CheckboxBlock from '../../components/SignUp/CheckboxBlock';
import CaptchaBlock from '../../components/SignUp/CaptchaBlock';
import BottomBlock from '../../components/SignUp/BottomBlock';
import FacebookBlock from '../../components/SignUp/FacebookBlock';
import GoogleBlock from '../../components/SignUp/GoogleBlock';
import CustomTitle from '../../components/Common/CustomTitle';

const SignUpSection = () => {
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    policyChecked: false,
    captchaChecked: false,
    validate: {
      firstName: null,
      lastName: null,
      email: null,
      password: null
    }
  });

  const submitHandler = event => {
    event.preventDefault();
    console.log(userInfo);
  };

  return (
    <div className="create-account">
      <div className="create-account-wrapper">
        <CustomTitle title="Create an account" />
        <form onSubmit={submitHandler}>
          <InputBlock userInfo={userInfo} setUserInfo={setUserInfo} />
          <CheckboxBlock
            checked={userInfo.policyChecked}
            setChecked={setUserInfo}
          />
          <CaptchaBlock setCaptchaChecked={setUserInfo} />
          <BottomBlock userInfo={userInfo} />
        </form>
      </div>
      <div className="create-account-social-network-wrapper">
        <CustomTitle title="Or register using" />
        <FacebookBlock />
        <GoogleBlock />
      </div>
    </div>
  );
};

export default SignUpSection;
