import { connect } from 'react-redux';
import FavouritesContent from './FavouritesContent';

const mapStateToProps = state => {
  return {
    productsIds: state.favoritsReducers.productIds
  };
};

const FavouritesItemsContainer = connect(
  mapStateToProps,
  null
)(FavouritesContent);

export default FavouritesItemsContainer;
