import React from 'react';
import CustomTitle from '../../components/Common/CustomTitle';
import MyAdsContent from '../../components/MyAds/MyAdsContent';
import './MyAdsSubpage.scss';

const MyAdsSubpage = () => {
  return (
    <div className="my-announcements">
      <CustomTitle title="My Ads" className="my-announcements__title" />
      <MyAdsContent />
    </div>
  );
};

export default MyAdsSubpage;
