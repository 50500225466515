import React, { useEffect } from 'react';
import CustomTitle from '../Common/CustomTitle';
import Avatar from './Avatar';
import './BusinessAccount.scss';
import InputBusinessBlock from './InputBusinessBlock';
import PropTypes from 'prop-types';
import axios from 'axios';

const BusinessAccount = ({
  businessAccountData,
  businessTypeData,
  setBusinessTypeData,
  setBusinessAccountData,
  setAddress,
  setSelectedBusinessType,
  setAvatar,
  setBusinessName,
  setBusinessDescription
}) => {
  useEffect(() => {
    axios
      .get(`/json/getBusinessAccountInfo.json`)
      .then(res => setBusinessTypeData(res.data.businessType));
  }, [setBusinessTypeData]);

  return (
    <div className="business-info">
      <CustomTitle
        title="My business account"
        className="business-info__title"
      />
      <Avatar
        className="business-info-avatar"
        img={businessAccountData.img}
        setAvatar={setAvatar}
      />
      <InputBusinessBlock
        businessTypeData={businessTypeData}
        setBusinessAccountData={setBusinessAccountData}
        businessAccountData={businessAccountData}
        setAddress={setAddress}
        setSelectedBusinessType={setSelectedBusinessType}
        setBusinessName={setBusinessName}
        setBusinessDescription={setBusinessDescription}
      />
    </div>
  );
};

BusinessAccount.propTypes = {
  businessAccountData: PropTypes.object,
  businessTypeData: PropTypes.array,
  setBusinessTypeData: PropTypes.func,
  setBusinessAccountData: PropTypes.func,
  setAddress: PropTypes.func,
  setSelectedBusinessType: PropTypes.func,
  setAvatar: PropTypes.func,
  setBusinessName: PropTypes.func,
  setBusinessDescription: PropTypes.func
};

export default BusinessAccount;
