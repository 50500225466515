import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './PersonalAccount.scss';
import InputPersonalBlock from './InputPersonalBlock';
import CustomTitle from '../Common/CustomTitle';
import Avatar from './Avatar';
import PropTypes from 'prop-types';
import axios from 'axios';

const PersonalAccount = ({
  setPersonalAccountData,
  personalAccountData,
  setValidFirstName,
  setInvalidFirstName,
  setValidLastName,
  setInvalidLastName,
  setValidEmail,
  setInvalidEmail,
  setAvatar,
  validation
}) => {
  useEffect(() => {
    axios
      .get(`/json/getPersonalAccountInfo.json`)
      .then(res => setPersonalAccountData(res.data.personalAccount));
  }, [setPersonalAccountData]);

  const validationInfo = obj => {
    const arr = Object.values(obj);
    return !arr.includes(false);
  };

  return (
    <div className="personal-info">
      <CustomTitle
        title="My personal account"
        className="personal-info__title"
      />
      <Avatar
        className="personal-info-avatar"
        img={
          personalAccountData.img
            ? personalAccountData.img
            : personalAccountData.imgDefault
        }
        setAvatar={setAvatar}
      />
      <form className="personal-info-form">
        <InputPersonalBlock
          personalAccountData={personalAccountData}
          validation={validation}
          setValidFirstName={setValidFirstName}
          setInvalidFirstName={setInvalidFirstName}
          setValidLastName={setValidLastName}
          setInvalidLastName={setInvalidLastName}
          setValidEmail={setValidEmail}
          setInvalidEmail={setInvalidEmail}
        />
        <Link to="/create-pass" className="personal-info__link">
          Change password
        </Link>
        <button
          type="button"
          className={
            validationInfo(validation)
              ? 'personal-info__btn'
              : 'personal-info__btn disabled'
          }
          onClick={() => console.log(personalAccountData)}
        >
          Save changes
        </button>
      </form>
    </div>
  );
};

PersonalAccount.propTypes = {
  personalAccountData: PropTypes.object,
  validation: PropTypes.object,
  setPersonalAccountData: PropTypes.func,
  setValidFirstName: PropTypes.func,
  setInvalidFirstName: PropTypes.func,
  setValidLastName: PropTypes.func,
  setInvalidLastName: PropTypes.func,
  setValidEmail: PropTypes.func,
  setInvalidEmail: PropTypes.func,
  setAvatar: PropTypes.func
};

export default PersonalAccount;
