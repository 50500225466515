import React from 'react';
import Breadcrumbs from '../../routes';
import SetUpProfileSection from '../../sections/SetUpProfile/SetUpProfileSection';

const SetUpProfilePage = () => {
  return (
    <React.Fragment>
      <Breadcrumbs />
      <SetUpProfileSection />
    </React.Fragment>
  );
};

export default SetUpProfilePage;
