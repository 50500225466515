import { SET_CATEGORIES_DATA } from './categoriesActions';

const initialState = {
  categoriesData: []
};

export const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES_DATA:
      return {
        ...state,
        categoriesData: [...action.payload]
      };

    default:
      return state;
  }
};
