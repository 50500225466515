import React from 'react';
import PropTypes from 'prop-types';
import './CustomTextarea.scss';

const CustomTextarea = ({
  classForContainer,
  value,
  handleChange,
  label,
  placeholder
}) => {
  return (
    <div className={classForContainer}>
      <textarea
        placeholder={placeholder}
        className="textarea"
        value={value}
        onChange={handleChange}
      />
      <label className="label-for-textarea">{label}</label>
    </div>
  );
};

CustomTextarea.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  classForContainer: PropTypes.string,
  icon: PropTypes.string
};

export default CustomTextarea;
