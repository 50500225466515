import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import './SideBlocksItem.scss';

export const SideBlocksItem = ({ slug }) => {
  let itemContent;

  if (slug === 'post') {
    itemContent = (
      <li className="side-container-list__item">
        <NavLink className="post-link" to={'/' + slug}>
          <i className="icon-plus" />
          <span className="post-text">Post an Ad</span>
        </NavLink>
      </li>
    );
  } else {
    itemContent = (
      <li className="side-container-list__item">
        <NavLink
          className="profile-container-link"
          to="my-account"
        >
          <div className="profile-container">
            <i className="icon-user" />
          </div>
        </NavLink>
        <span className="auth-text">
          <NavLink
            className="sign-in-link"
            to={'/' + slug[0]}
            activeClassName="active"
          >
            Login
          </NavLink>
          <span className="sign-up-text-between-link"> or </span>
          <NavLink
            className="sign-up-link"
            to={'/' + slug[1]}
            activeClassName="active"
          >
            Register
          </NavLink>
        </span>
      </li>
    );
  }

  return <React.Fragment>{itemContent}</React.Fragment>;
};

export default SideBlocksItem;

SideBlocksItem.propTypes = {
  slug: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};
