import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AccountProducts from '../Account/AccountProducts';

const MyAdsContent = () => {
  const [ads, setAds] = useState([]);

  useEffect(() => {
    axios.get(`/json/getMyAds.json`).then(res => setAds(res.data.myAds));
  }, [setAds]);

  return <AccountProducts products={ads} extend="My Ads" />;
};

export default MyAdsContent;
