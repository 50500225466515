import React from 'react';
import CustomTitle from '../../components/Common/CustomTitle';
import './BusinessSection.scss';

const businessItems = [
  {
    subtitle: 'Subtitle here',
    text: `Yse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et
            occaecat nisi ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in
            tempor enim minim incididunt velit tempor irure mollit voluptate consequat commodo.
            Product description, dolor esse eu minim incididunt aliquip dolore magna eiusmod
            incididunt mollit et occaecat nisi ex aute elit velit cupidatat eiusmod excepteur incididunt nulla
            in tempor enim minim incididunt velit tempor irure mollit voluptate consequat commodo.Product description,
            dolor esse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi ex aute
            elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt velit tempor
            irure mollit voluptate consequat commodo.`
  },
  {
    subtitle: 'Subtitle here',
    text: `Yse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et
            occaecat nisi ex aute elit velit cupidatat eiusmod excepteur incididunt nulla in
            tempor enim minim incididunt velit tempor irure mollit voluptate consequat commodo.
            Product description, dolor esse eu minim incididunt aliquip dolore magna eiusmod
            incididunt mollit et occaecat nisi ex aute elit velit cupidatat eiusmod excepteur incididunt nulla
            in tempor enim minim incididunt velit tempor irure mollit voluptate consequat commodo.Product description,
            dolor esse eu minim incididunt aliquip dolore magna eiusmod incididunt mollit et occaecat nisi ex aute
            elit velit cupidatat eiusmod excepteur incididunt nulla in tempor enim minim incididunt velit tempor
            irure mollit voluptate consequat commodo.`
  }
];

const BusinessSection = () => {
  return (
    <div className="business">
      <CustomTitle title="For Business" />
      <div className="business-content">
        {businessItems.map(item => {
          return (
            <div className="business-text-block">
              <h3 className="business-text__subtitle">{item.subtitle}</h3>
              <p className="business__text">{item.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BusinessSection;
