import React from 'react';
import CustomCheckboxToggle from '../Common/CustomCheckboxToggle';
import './Notify.scss';

const labelItems = [
  {
    id: '1',
    label: 'New products'
  },
  {
    id: '2',
    label: 'New suppliers'
  },
  {
    id: '3',
    label: 'New deals'
  },
  {
    id: '4',
    label: 'New message'
  }
];

const Notify = () => {
  return (
    <div className="notify">
      <p className="notify__title">Notify me about</p>
      <div className="notify-checkbox-block">
        {labelItems.map(item => {
          return (
            <CustomCheckboxToggle
              label={item.label}
              key={item.id}
              id={item.id}
              className="notify-checkbox-block__item"
            />
          );
        })}
      </div>
    </div>
  );
};

export default Notify;
